import { Box, CardMedia, Grid, Icon, IconButton, Typography } from "@mui/material";
import { collection, deleteDoc, doc, getDocs, orderBy, query } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";
import { Loading } from "../../themes/themes";
import getThumbnail from "./getThumbnail";
import isFileImage from "./isFileImage";
import DeleteIcon from "@mui/icons-material/Delete";
import deleteFile from "./deleteFile";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import convertToThumbnailName from "./convertToThumbnailName";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function FilesGallery({ handleClick }) {
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            setLoading(true);
            const collectionRef = collection(db, "users", user.id, "files");
            const q = query(collectionRef, orderBy("date", "desc"));
            const snapshot = await getDocs(q);
            const fileDetails = mapSnapshot(snapshot);

            // Sort files by updated date, latest first
            checkThumbnails(fileDetails, setFiles);
            setLoading(false);
        };

        fetchImages();
    }, [user.id]);

    const isImage = (file) => {
        if (file.type.includes("image")) return true;
        return false;
    };

    const confirmation = useConfirmation();

    const handleDelete = async (file) => {
        console.log(file);

        const response = await await confirmation("Delete file?", "Are you sure you want to delete this file?");
        if (response) {
            const docRef = doc(db, "users", user.id, "files", file.id);
            await deleteDoc(docRef);
            deleteFile(file.fullPath);
            const thumbnailFullPath = convertToThumbnailName(file.fullPath);
            deleteFile(thumbnailFullPath);

            const newFiles = [...files];
            const index = newFiles.findIndex((f) => f.id === file.id);
            newFiles.splice(index, 1);
            setFiles(newFiles);
        }
    };

    const handleOpen = (file) => {
        // Check if the file.downloadURL exists
        if (file && file.downloadURL) {
            // Open the URL in a new window/tab
            window.open(file.downloadURL, "_blank");
        } else {
            console.error("Invalid file or missing download URL");
        }
    };

    return (
        <Grid container spacing={2}>
            <Loading loading={loading} />
            {files.map((file, index) => (
                <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
                    <Box width="100%" sx={{ borderBottom: "1px solid grey" }}>
                        {isImage(file) ? (
                            <Box display="flex" alignItems={"center"}>
                                <CardMedia
                                    component="img"
                                    image={file.thumbnailURL || file.downloadURL}
                                    alt="file"
                                    onClick={() => handleClick(file)}
                                    sx={{ cursor: "pointer", width: "80%" }}
                                />
                                <Icons handleDelete={handleDelete} handleOpen={handleOpen} file={file} />
                            </Box>
                        ) : (
                            <Box display="flex" alignItems={"center"}>
                                <Icon
                                    onClick={() => handleClick(file)}
                                    sx={{ fontSize: "45px", width: "80%", cursor: "pointer" }}
                                >
                                    article
                                </Icon>
                                <Icons handleDelete={handleDelete} handleOpen={handleOpen} file={file} />
                            </Box>
                        )}
                        <Typography variant="body2" noWrap>
                            {file.name}
                        </Typography>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
}

async function checkThumbnails(files, setFiles) {
    if (files) {
        const newFiles = [...files];
        await Promise.all(
            newFiles.map(async (file, j) => {
                if (isFileImage(file)) {
                    const thumbnailURL = await getThumbnail(file);
                    if (thumbnailURL) {
                        newFiles[j] = { ...newFiles[j], thumbnailURL };
                    }
                    return thumbnailURL;
                }
                return Promise.resolve();
            })
        );
        setFiles(newFiles);
    } else {
        setFiles();
    }
}

const Icons = ({ handleDelete, file, handleOpen }) => {
    return (
        <Box display="flex" flexDirection={"column"} justifyContent={"flex-start"} height={"100%"}>
            <IconButton sx={{ alignSelf: "flex-start" }} onClick={() => handleOpen(file)} size="small">
                <OpenInNewIcon />
            </IconButton>
            <IconButton sx={{ alignSelf: "flex-start" }} onClick={() => handleDelete(file)} size="small">
                <DeleteIcon color="error" />
            </IconButton>
        </Box>
    );
};
