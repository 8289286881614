import { useTheme } from "@emotion/react";
import { DoneAllOutlined } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, Grid, IconButton, Link, Paper, styled, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { blue, green, grey, red } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import useList from "../context-utils/ListContext";
import { tokens } from "../theme";
import { ChipC, GridFlexBox, Name } from "../themes/themes";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import convertStringToCamelCase from "../utils-functions/convertStringToCamelCase";
import { notification } from "../utils-functions/notification";
import SelectFile from "./SelectFileComponent";
import UndoComponent2 from "./UndoComponent2";

export default function UploadExcel2({
    handleUpload = () => {},
    customHeader = [],
    requiredFields,
    setRequiredFields
}) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [headerIndex, setHeaderIndex] = useState(0);
    const [headerName, setHeaderName] = useState([]);
    const [originalHeaderName, setOriginalHeaderName] = useState([]);
    const [prevRows, setPrevRows] = useState();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const MyInput = styled("input")({
        marginTop: 10,
        marginBottom: 10,
        fontFamily: "Roboto",
        paddingTop: 10,
        paddingBottom: 10,
        width: "100%",
        backgroundColor: "transparent",
        borderColor: grey[500],
        color: colors.grey[600],
        border: "0"
    });

    useEffect(() => {
        console.log(rows);
        console.log(columns);
        if (!arrayIsEmpty(rows)) {
            const headerName = [...Array(columns.length)].map((item, i) => {
                const f = requiredFields.find((field) => field.mappedTo === i);
                if (f) return f.id;
                const camelCase = convertStringToCamelCase(rows[0][i]);
                return camelCase;
            });
            setHeaderName(headerName);
            setOriginalHeaderName(headerName);
        }
    }, [rows, columns]);

    useEffect(() => {
        console.log(requiredFields);
    }, [requiredFields]);

    useEffect(() => {
        if (!arrayIsEmpty(rows)) handleSetCustomHeader();
    }, [rows]);

    const handleClickUpload = (files) => {
        setRequiredFields((prevState) => {
            return prevState.map((field) => {
                return { ...field, set: false, mappedTo: null };
            });
        });
        console.log(files);
        fileHandler(files);
    };

    const fileHandler = (files) => {
        let fileObj = files[0];

        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            const { rows, cols } = resp;
            if (err) {
                console.log(err);
            } else {
                const newRows = [];
                rows.forEach((row) => {
                    if (row[0]) {
                        newRows.push(row);
                    }
                });
                setRows(newRows);
                setColumns(cols);
            }
        });
    };

    const handleCheck = (i) => {
        setHeaderIndex(i);
    };

    const handleSetHeader = () => {
        if (!headerIndex) {
            notification("No header", "Press check the header box", "warning");
        } else {
            const newRows = rows.slice(headerIndex, rows.length);
            setRows((prevState) => {
                if (prevState && prevState === newRows) return prevState;
                setPrevRows(prevState);
                return newRows;
            });
            console.log(newRows);
            setHeaderIndex(0);
        }
    };

    const handleChange = (e) => {
        const { value, id } = e.target;
        const newHeaderName = [...headerName];
        newHeaderName[id] = value;
        setHeaderName(newHeaderName);
    };

    const convertToObject = async () => {
        if (arrayIsEmpty(rows)) {
            notification("Empty array", "There is no data to convert. Make sure you uploaded an Excel file", "warning");
            return;
        }

        const data = rows.slice(1, rows.length).map((row) => {
            let object = {};
            [...Array(row.length)].map((item, j) => {
                let cell = row[j];
                object[headerName[j]] = cell || "";
            });
            return object;
        });
        console.log(headerName);
        if (allMapped) {
            const response = await handleUpload(data);
            if (response) {
                setRows([]);
                setRequiredFields((prevState) => {
                    return prevState.map((field) => {
                        return { ...field, set: false, mappedTo: null };
                    });
                });
            }
        } else {
            notification("Required fields", "Please map all required fields", "warning");
        }
    };

    const handleSetCustomHeader = () => {
        if (arrayIsEmpty(customHeader)) return;
        if (customHeader.length === rows[0].length) {
            setHeaderName(customHeader);
            notification("Success", "Added Custom Header", "success");
        } else {
            notification("Error", "Custom Header length is NOT the same as number of columns", "warning");
        }
    };

    const handleUndo = () => {
        setRows(prevRows);
        setHeaderIndex(0);
        setPrevRows();
    };

    const handleAutoMap = () => {
        headerName.forEach((header, i) => {
            const index = requiredFields.findIndex((field) => field.id === header);
            if (index !== -1) {
                setRequiredFields((prevState) => {
                    const newRequiredFields = [...prevState];
                    newRequiredFields[index].set = true;
                    newRequiredFields[index].mappedTo = i;
                    return newRequiredFields;
                });
            }
        });
    };

    const allMapped = useMemo(() => {
        return requiredFields?.every((item) => item.set);
    }, [requiredFields]);

    const displayRows = useMemo(() => {
        if (rows.length < 41) {
            return rows;
        } else {
            return rows.slice(0, 30).concat(rows.slice(rows.length - 10, rows.length));
        }
    }, [rows]);

    return (
        <>
            <Grid container>
                <GridFlexBox>
                    <SelectFile handleClickUpload={handleClickUpload} />
                </GridFlexBox>
                <GridFlexBox fs>
                    <Name color={allMapped ? green[500] : red[500]}>
                        {allMapped ? "Everything ok" : "Required fields*"}
                    </Name>
                </GridFlexBox>

                <GridFlexBox fs gap={1} m1 fw>
                    {requiredFields?.map((item, i) => !item.set && <ChipC key={item.id}>{item.label}</ChipC>)}
                </GridFlexBox>

                {!arrayIsEmpty(rows) && (
                    <GridFlexBox fs>
                        <IconButton onClick={handleAutoMap}>
                            <ChipC color="green">Automap</ChipC>
                        </IconButton>
                    </GridFlexBox>
                )}

                <Paper style={{ overflow: "auto", width: "99vw", height: "40vh" }} variant="outlined">
                    <Box m={1}>
                        <Grid item xs={12} height={"60vh"} flexShrink={0} flexWrap="nowrap">
                            {/* Display header and Field */}
                            <Box display={"flex"} alignItems="center">
                                {rows[0] &&
                                    [...Array(rows[0].length)].map((i, j) => (
                                        <React.Fragment key={j}>
                                            <Box>{!j && <Checkbox disabled sx={{ visibility: "hidden" }} />}</Box>
                                            <Box
                                                sx={{
                                                    width: "150px"
                                                }}
                                                flexShrink={0}
                                            >
                                                <MySelect
                                                    requiredFields={requiredFields}
                                                    setRequiredFields={setRequiredFields}
                                                    j={j}
                                                    setHeaderName={setHeaderName}
                                                    originalHeaderName={originalHeaderName}
                                                    allMapped={allMapped}
                                                />
                                            </Box>
                                        </React.Fragment>
                                    ))}
                            </Box>
                            <Box display={"flex"} alignItems="center">
                                {rows[0] &&
                                    [...Array(rows[0].length)].map((item, j) => (
                                        <React.Fragment key={j}>
                                            <Box>{!j && <Checkbox disabled sx={{ visibility: "hidden" }} />}</Box>
                                            <Box
                                                sx={{
                                                    width: "150px",
                                                    borderBottom: "solid 1px"
                                                }}
                                                flexShrink={0}
                                                flexGrow={0}
                                            >
                                                <MyInput
                                                    id={j}
                                                    size="small"
                                                    value={arrayIsEmpty(headerName) ? "" : headerName[j]}
                                                    readOnly
                                                />
                                            </Box>
                                        </React.Fragment>
                                    ))}
                            </Box>

                            {displayRows?.map((row, i) => (
                                <Box display={"flex"} key={i} alignItems="center">
                                    {[...Array(row.length)].map((item, j) => (
                                        <React.Fragment key={j}>
                                            <Box>
                                                {!j && (
                                                    <Checkbox
                                                        checked={headerIndex === i ? true : false}
                                                        onChange={() => handleCheck(i)}
                                                        disabled
                                                        sx={{ visibility: "hidden" }}
                                                    />
                                                )}
                                            </Box>
                                            <Box sx={{ width: "150px" }} flexShrink={0}>
                                                <Typography fontSize="small" noWrap>
                                                    {row[j]}
                                                </Typography>
                                            </Box>
                                        </React.Fragment>
                                    ))}
                                </Box>
                            ))}
                        </Grid>
                    </Box>
                </Paper>
                <GridFlexBox>
                    <Box display="flex" gap={2} alignItems="center" flexWrap={"wrap"} mt={"8px"}>
                        <Button variant="contained" onClick={handleSetHeader} disabled>
                            Set Header
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleSetCustomHeader}
                            color={"info"}
                            disabled={arrayIsEmpty(customHeader)}
                        >
                            Set Custom Header
                        </Button>
                        <Button variant="contained" onClick={convertToObject} color={"warning"}>
                            Upload
                        </Button>
                        <Typography variant="body2">Total number of row: {rows.length}</Typography>
                    </Box>
                </GridFlexBox>
                <br />
                <br />
                <br />
                <br />
            </Grid>
            <UndoComponent2 handleUndo={handleUndo} object={prevRows} setObject={setPrevRows} />
        </>
    );
}

const MySelect = ({ requiredFields, setRequiredFields, setHeaderName, j, originalHeaderName, allMapped }) => {
    const list = useList();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleClick = async () => {
        console.log("click");
        let objects = requiredFields.filter((field) => !field.set);
        const response = await list(
            [{ label: "Please select", id: "x" }, ...objects],
            "Map fields",
            "label",
            "face",
            false,
            false
        );
        if (response) {
            if (response.id === "x") {
                const index = requiredFields.findIndex((field) => field.mappedTo === j);
                if (index !== -1) {
                    const newRequiredFields = [...requiredFields];
                    setHeaderName((prev) => {
                        const newHeader = [...prev];
                        newHeader[j] = originalHeaderName[j];
                        return newHeader;
                    });
                    newRequiredFields[index].set = false;
                    delete newRequiredFields[index].mappedTo;
                    setRequiredFields(newRequiredFields);
                    return;
                } else {
                    return;
                }
            }
            const findExistingIndex = requiredFields.findIndex((field) => field.mappedTo === j);
            if (findExistingIndex !== -1) {
                setHeaderName((prev) => {
                    const newHeader = [...prev];
                    newHeader[j] = originalHeaderName[j];
                    return newHeader;
                });
                delete requiredFields[findExistingIndex].set;
                delete requiredFields[findExistingIndex].mappedTo;
            }
            const newItem = { ...response, mappedTo: j, set: true };
            const index = requiredFields.findIndex((field) => field.id === response.id);
            const newRequiredFields = [...requiredFields];
            newRequiredFields.splice(index, 1, newItem);
            setRequiredFields(newRequiredFields);
            setHeaderName((prev) => {
                const newHeader = [...prev];
                newHeader[j] = newItem.id;
                return newHeader;
            });
        }
    };

    const [label, set] = useMemo(() => {
        const f = requiredFields.find((field) => field.mappedTo === j);
        if (f) return [f.label, true];
        if (allMapped) return ["", false];
        return ["Please select", false];
    }, [requiredFields, j]);

    return (
        <Box
            onClick={handleClick}
            width={"100%"}
            border="1px solid"
            p={1}
            borderColor={grey[500]}
            display={"flex"}
            sx={{ cursor: "pointer", backgroundColor: set && blue[500] }}
            justifyContent={"space-between"}
            alignItems={"center"}
            color={set && "white"}
        >
            {label}
            {label ? <ArrowDropDownIcon /> : <DoneAllOutlined />}
        </Box>
    );
};
