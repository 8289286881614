function convertToThumbnailName(fullPath) {
    var lastSlash = fullPath.lastIndexOf("/");
    var pathWithoutName = fullPath.substring(0, lastSlash + 1); // extract the path without the name

    var name = fullPath.substring(lastSlash + 1); // extract the name from the full path

    var lastDot = name.lastIndexOf(".");
    var nameWithoutExtension = name.substring(0, lastDot); // remove the extension

    var thumbnailName = nameWithoutExtension + "_1080x1080.jpeg"; // append '_1080x1080.jpeg'

    return pathWithoutName + "/thumbnails/" + thumbnailName; // return new full path
}

export default convertToThumbnailName;
