import SignalWifiStatusbar4BarIcon from "@mui/icons-material/SignalWifiStatusbar4Bar";
import { Box, IconButton, Typography, Paper, Button } from "@mui/material";
import { useState } from "react";
import ConnectWhatsappComponent from "../WhatsappWebPage/ConnectWhatsappComponent";

export default function WhatsappConnectionComponent({
    whatsappUser,
    whatsappSession,
    setApiAddress,
    setWhatsappSession,
    setWhatsappPhone,
    setSecret
}) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen((prev) => !prev);
    };

    const color = whatsappSession ? "success" : "error";

    return (
        <Box>
            <Box display="flex" alignItems={"center"}>
                <IconButton onClick={handleClickOpen}>
                    <SignalWifiStatusbar4BarIcon color={color} />
                </IconButton>
                <Typography>{whatsappSession ? "Connected" : "Not Connected"}</Typography>
            </Box>

            <Box display={open ? "block" : "none"} position={"absolute"} zIndex={10000} left={"8px"}>
                <Paper>
                    <Box m="16px">
                        <Button color="error" onClick={handleClickOpen}>
                            Close
                        </Button>
                        <ConnectWhatsappComponent
                            whatsappUser={whatsappUser}
                            setApiAddress={setApiAddress}
                            setWhatsappSession={setWhatsappSession}
                            setWhatsappPhone={setWhatsappPhone}
                            setSecret={setSecret}
                        />
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
}
