import {
    Box,
    Chip,
    Dialog,
    DialogTitle,
    Grid,
    Icon,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography,
    Paper
} from "@mui/material";
import { useEffect, useState } from "react";

export default function SimpleDialog(props) {
    const { onClose, onClick, open, targetObject, objects } = props;
    const [search, setSearch] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const handleListItemClick = (targetObject, object) => {
        onClick(targetObject, object);
    };

    useEffect(() => {
        let numberOfObjects = 0;
        let numberOfPages = 1;
        if (objects) {
            numberOfObjects = objects.length;
            numberOfPages = Math.floor(numberOfObjects / 10) + 1;
            setTotalPages(numberOfPages);
        }
    }, [objects]);

    let filteredObjects = [];
    if (objects) {
        filteredObjects = objects.filter((object) => {
            if (object[targetObject.displayName]) {
                return object[targetObject.displayName].toLowerCase().includes(search.toLowerCase());
            } else {
                return false;
            }
        });

        filteredObjects = filteredObjects.slice(pageNumber * 10 - 10, pageNumber * 10);
    }

    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
            <Paper sx={{ height: "80vh", width: "500px" }}>
                <DialogTitle id="simple-dialog-title">
                    <Grid container>
                        <Grid item xs={6}>
                            <Box display="flex">
                                <Typography>Select {targetObject.object}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} align="right">
                            <Icon style={{ cursor: "pointer" }} onClick={onClose}>
                                clear
                            </Icon>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Box m={2}>
                    <TextField
                        label={"Search " + targetObject.object}
                        variant="outlined"
                        value={search}
                        fullWidth
                        onChange={(e) => {
                            setSearch(e.target.value);
                            setPageNumber(1);
                        }}
                        autoComplete="off"
                    />
                </Box>

                <List>
                    {filteredObjects &&
                        filteredObjects.map((object) => (
                            <ListItem button onClick={() => handleListItemClick(targetObject, object)} key={object.id}>
                                <ListItemText primary={object[targetObject.displayName]} />
                            </ListItem>
                        ))}
                    <Box m={2} gap="8px" display="flex">
                        <Chip
                            label="Last Page"
                            clickable
                            disabled={pageNumber === 1}
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                                setPageNumber(pageNumber - 1);
                            }}
                        />
                        <Chip
                            label="Next Page"
                            clickable
                            disabled={totalPages === pageNumber || filteredObjects.length < 10}
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                                setPageNumber(pageNumber + 1);
                            }}
                        />
                    </Box>
                </List>
            </Paper>
        </Dialog>
    );
}
