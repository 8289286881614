import axios from "axios";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export const whatsappMessage = async (token, fromNumber, toNumber, message, type, user, openNotification) => {
    var data = JSON.stringify({
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: `${toNumber}`,
        type: `${type}`,
        text: {
            preview_url: false,
            body: `${message}`
        }
    });

    var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://graph.facebook.com/v16.0/${fromNumber}/messages`,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        data: data
    };

    try {
        const response = await axios(config);
        console.log(response.data);

        const wamId = response.data?.messages?.[0]?.id;
        const roomId = fromNumber + "-" + toNumber;
        const roomRef = doc(db, "messages", roomId);
        await setDoc(roomRef, { lastUpdated: new Date(), user: user.id, toNumber: toNumber }, { merge: true });
        console.log("Room ", roomId, " updated");

        const messageRef = doc(db, "messages", roomId, "messages", wamId);
        await setDoc(messageRef, { fromNumber, toNumber, message, date: new Date(), user: user.id, wamId, type });
        console.log("Added message into Firestore");

        return true;
    } catch (err) {
        console.log(err);
        console.log(err.response?.data?.error?.message);
        if (err.response?.data?.error?.message === "Invalid OAuth access token - Cannot parse access token") {
            openNotification("Invalid token to access Whatsapp. Please login to Facebook again", "error");
        }
        return false;
    }
};
