export default function getAssignList(leads) {
    if (leads) {
        let array = [];

        leads.forEach((lead) => {
            let members = [];
            lead.assignments?.forEach((assignment) => {
                let index = array.findIndex((a) => a.email === assignment.assign.email);
                let found = members.find((m) => m === assignment.assign.email);
                if (!found) {
                    members.push(assignment.assign.email);
                    if (index !== -1) {
                        array[index] = { ...array[index], count: array[index].count + 1 };
                        return;
                    }
                    const assign = { ...assignment.assign, count: 1 };
                    array.push(assign);
                }
            });
        });
        array.sort((a, b) => b.count - a.count);
        return array;
    } else {
        return null;
    }
}
