import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { signOut } from "../functions/signOut";
import { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../context-utils/NotificationContext";

export default function SignOutPage() {
    const { setUser, setAuthUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const { openNotification } = useContext(NotificationContext);

    useEffect(() => {
        exit();
    }, []);

    const exit = async () => {
        await signOut();
        setAuthUser();
        setUser();
        navigate("/");
        openNotification("Bye bye. Come again~", "info");
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Button onClick={exit} variant="contained" color="error">
                    Click here to Sign Out
                </Button>
            </Box>
        </Container>
    );
}
