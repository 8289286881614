import { GoogleGenerativeAI } from "@google/generative-ai";
import SendIcon from "@mui/icons-material/Send";
import { Box, Container, Grid, IconButton, Paper, TextField } from "@mui/material";
import React, { useEffect } from "react";
import MarkDown from "../components/MarkDown";
import { GridContainer, GridDivider, GridFlexBox, Loading, Name, Title } from "../themes/themes";
import { toast } from "react-hot-toast";

// Access your API key as an environment variable
const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GOOGLE_AI_API_KEY);

export default function GoogleAIPage() {
    const [prompt, setPrompt] = React.useState("Hello Google");
    const [isLoading, setIsLoading] = React.useState(false);
    const [chatHistory, setChatHistory] = React.useState([]);

    // useEffect(() => {
    //     const systemPrompt = [
    //         {
    //             role: "user",
    //             parts: `Imagine you are a property agent that can set appointment to see the client. The current date and time is 2023-12-15 10:25pm.
    //     If the appointment is secure with confirmed date and time, generate a response in the following format:
    //     {function: "set_appointment", date: {date}, time: {time}}
    //     Example 1:
    //     user: I want to set an appointment.
    //     model: Sure. What date and time would you like to meet?
    //     user: 12 Dec 2023
    //     model: Sure. What time?
    //     user: 2pm
    //     model: {"function": "set_appointment", "date": "2023-12-12", "time": "2pm"}
    //     Example 2:
    //     user: I want to meet you.
    //     model: Sure. What date and time would you like to meet?
    //     user: 12 Dec 2023
    //     model: Sure. What time?
    //     user: 2pm
    //     model: {"function": "set_appointment", "date": "2023-12-12", "time": "2pm"}
    //     If you understand reply yes and we start the role play by waiting for my response or questions`
    //         },
    //         { role: "model", parts: "Yes, I understand." },
    //         { role: "user", parts: "I want to meet you." },
    //         { role: "model", parts: "What date and time?" },
    //         { role: "user", parts: "12 Dec 2023" },
    //         { role: "model", parts: "What time?" },
    //         { role: "user", parts: "2pm" },
    //         { role: "model", parts: '{"function": "set_appointment", "date": "2023-12-12", "time": "2pm"}' }
    //     ];
    //     setChatHistory(systemPrompt);
    // }, []);

    const handleClickSend = () => {
        run();
    };

    async function run() {
        // For text-only input, use the gemini-pro model
        const model = genAI.getGenerativeModel({ model: "gemini-pro" });

        // Start a chat session
        const chat = model.startChat({
            history: chatHistory
        });

        setIsLoading(true);
        const index = chatHistory.length + 1;
        setChatHistory((prev) => [...prev, { role: "user", parts: prompt }]);
        const result = await chat.sendMessageStream(prompt);
        let text = "";
        for await (const chunk of result.stream) {
            const chunkText = chunk.text();
            text += chunkText;
            // Remove excessive newline characters
            let cleanedText = text.replace(/\n{2,}/g, "\n");
            cleanedText.replace(/ {2}\n/g, "\n");
            setChatHistory((prev) => {
                const newChatHistory = [...prev];
                newChatHistory[index] = { role: "model", parts: cleanedText };
                return newChatHistory;
            });
        }
        if (text.includes("set_appointment")) {
            // Regular expression to match the JSON string within curly braces
            var regex = /{.*?}/;

            // Finding the match
            var jsonString = text.match(regex)[0];

            // Parsing the JSON string into an object
            var obj = JSON.parse(jsonString);
            console.log(obj);
            toast.success("Appointment set successfully!");
        }
        setIsLoading(false);
        setPrompt("");
    }

    return (
        <Container>
            <GridContainer>
                <Title>Google AI Playground</Title>
                <GridDivider />
                <GridFlexBox>
                    <Paper
                        sx={{ width: "100%", height: "60vh", overflowY: "auto", padding: "8px", marginBottom: "8px" }}
                    >
                        <Grid container display="flex">
                            {chatHistory.map((chat, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <GridFlexBox fs>
                                            <Name bold>{chat.role === "user" ? "Dave" : "AI"}</Name>
                                        </GridFlexBox>
                                        <Grid item xs={12} display={"flex"}>
                                            <Box sx={{ width: "100%", whiteSpace: "pre-wrap", margin: "4px" }}>
                                                <MarkDown markdown={chat.parts} />
                                            </Box>
                                        </Grid>
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    </Paper>
                </GridFlexBox>
                <GridFlexBox>
                    <TextField
                        fullWidth
                        size="small"
                        disabled={isLoading}
                        multiline
                        rows={5}
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                                handleClickSend();
                                e.preventDefault(); // Prevents the default action of the enter key in a form
                            }
                            // No need to handle Shift + Enter explicitly, as it will work by default
                        }}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={handleClickSend}>
                                    <SendIcon />
                                </IconButton>
                            )
                        }}
                    />
                </GridFlexBox>
                <Loading loading={isLoading} />
            </GridContainer>
        </Container>
    );
}
