import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";

export function getUserPages(user, setPages) {
    try {
        const collectionRef = collection(db, "pages");
        const q = query(collectionRef, where("admins", "array-contains", user.email));
        return onSnapshot(q, (snapshot) => {
            const pages = mapSnapshot(snapshot);
            setPages(pages);
        });
    } catch (error) {
        console.log(error);
    }
}
