import React, { createContext, useState } from "react";
import { useRef } from "react";
import InputDialogComponent from "./InputDialogComponent";

export const InputContext = createContext();

export const InputProvider = ({ children }) => {
    const [input, setInput] = useState();
    const [state, setState] = useState({ title: "", text: "", label: "" });
    const [open, setOpen] = useState(false);
    const [initialValue, setInitialValue] = useState();

    const handleSave = (value) => {
        setInput(value);
        setInitialValue("");
        awaitingPromiseRef.current.resolve(value);
    };

    const awaitingPromiseRef = useRef();

    // const {openInputDialog} = useContext(InputContext)
    // const response = await openInputDialog(title, text, label, startValue)
    const openInputDialog = async (title, text, label, startValue) => {
        setState({
            title: title,
            text: text,
            label: label
        });
        setInitialValue(startValue);
        setOpen(true);

        return new Promise((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    return (
        <InputContext.Provider value={{ input, setState, setInitialValue, setOpen, openInputDialog }}>
            {children}
            <InputDialogComponent
                open={open}
                setOpen={setOpen}
                title={state.title}
                text={state.text}
                label={state.label}
                onSave={handleSave}
                initialValue={initialValue}
            />
        </InputContext.Provider>
    );
};

 // const response = await input(title, text, label, startValue)
export function useInput() {
    const { openInputDialog } = React.useContext(InputContext);
    return openInputDialog;
}
