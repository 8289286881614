import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, IconButton, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

export default function MenuComponent({ client, handleDelete }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = (e) => {
        setAnchorEl(null);
        e.stopPropagation();
    };

    return (
        <Box>
            <IconButton size="small" onClick={handleClick}>
                <KeyboardArrowDownIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
            >
                <MenuItem
                    onClick={(e) => {
                        handleClose(e);
                        handleDelete(client);
                    }}
                >
                    <Typography sx={{ marginRight: "8px" }}>Delete</Typography>
                    <DeleteIcon color="error" />
                </MenuItem>
            </Menu>
        </Box>
    );
}
