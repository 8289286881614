import { collection, getDocs, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";

export default function loadFoldersRT(user, setFolders) {
    try {
        const collectionRef = collection(db, "users", user.id, "folders");
        const q = query(collectionRef, orderBy("updated", "desc"));
        return onSnapshot(q, (snapshot) => {
            const folders = mapSnapshot(snapshot);
            console.log(folders);
            setFolders(folders);
        });
    } catch (error) {
        console.log(error);
    }
}

export async function loadFolders(user) {
    try {
        const collectionRef = collection(db, "users", user.id, "folders");
        const q = query(collectionRef, orderBy("updated", "desc"));
        const snapshot = await getDocs(q);
        const folders = mapSnapshot(snapshot);
        console.log(folders);
        return folders;
    } catch (error) {
        console.log(error);
    }
}
