import streamData from "./streamData";

export default async function generateGptResponse(prompt, setResponse, setGenerating, model = "gpt-3.5-turbo") {
    let msgs = [
        { role: "system", content: "You are a helpful assistant who answer questions from the user" },
        { role: "user", content: prompt }
    ];

    try {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`
            },
            body: JSON.stringify({
                model: model,
                messages: msgs,
                temperature: 0.5,
                stream: true,
                n: 1
            })
        };

        setGenerating(true);

        const response = await fetch("https://api.openai.com/v1/chat/completions", requestOptions);
        // const result = await response.text();

        // This data is a ReadableStream
        const data = response.body;
        if (!data) {
            return;
        }

        await streamData(data, setResponse);
        setGenerating(false);
        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
}
