import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function deleteFlow(flow, user) {
    try {
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id);
        await deleteDoc(docRef);
        console.log("Document deleted with ID: ", flow.id);
    } catch (error) {
        console.log(error);
    }
}
