import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";

export default function loadLead(leadId, setLead) {
    try {
        const leadRef = doc(db, "leads", leadId);
        return onSnapshot(leadRef, (snapshot) => {
            let lead = mapDocSnapshot(snapshot);
            lead = mapData(lead);
            setLead(lead);
        });
    } catch (error) {
        console.log(error);
    }
}

const mapData = (lead) => {
    let data = {};
    if (lead.field_data) {
        lead.field_data.forEach((item) => {
            if (item.name.includes("name") || item.name.includes("全名")) data = { ...data, name: item.values[0] };
            else if (item.name.includes("email") || item.name.includes("邮箱"))
                data = { ...data, email: item.values[0] };
            else if (item.name.includes("phone") || item.name.includes("手机号"))
                data = { ...data, phone: item.values[0] };
            else {
                data = { ...data, [item.name]: item.values[0] };
            }
        });
    }
    return {
        ...lead,
        ...data
    };
};
