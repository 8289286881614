import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function deleteChatGptHistory(item, user) {
    try {
        const docRef = doc(db, "users", user.id, "chatGptHistory", item.id);
        await deleteDoc(docRef);
        const docRef2 = doc(db, "users", user.id, "chatGptHistoryIds", item.id);
        await deleteDoc(docRef2);
    } catch (error) {
        console.log(error);
    }
}
