import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Container, Grid, IconButton } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import React, { useContext, useEffect } from "react";
import { useInput } from "../../context-utils/InputContext";
import { AuthContext } from "../../context/AuthContext";
import { StoreContext } from "../../context/StoreContext";
import { db } from "../../firebase/firebase-utils";
import loadFoldersRT from "../../functions/loadFolders";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridDivider, GridFlexBox, Name, Title } from "../../themes/themes";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import deleteFolder from "../../functions/deleteFolder";
import toast from "react-hot-toast";

export default function FoldersManagementPage() {
    const { folders, setFolders } = useContext(StoreContext);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const getData = async () => {
            loadFoldersRT(user, setFolders);
        };
        getData();
    }, [user]);

    const input = useInput();

    const handleEdit = async (folder) => {
        const response = await input("Edit folder name", "folder name", "Folder Name", folder.folderName);
        if (response) {
            const docRef = doc(db, "users", user.id, "folders", folder.id);
            await updateDoc(docRef, { folderName: response });
        }
    };

    const confirm = useConfirmation();
    const handleDelete = async (folder) => {
        const response = await confirm("Are you sure you want to delete this folder?");
        if (response) {
            await deleteFolder(folder, user);
            toast.success("Folder deleted successfully");
        }
    };

    return (
        <Container maxWidth={"sm"}>
            <Grid container>
                <GridFlexBox>
                    <Title>Folders Management</Title>
                </GridFlexBox>
                <GridDivider />
                {folders?.map((folder) => (
                    <React.Fragment key={folder.ud}>
                        <GridFlexBox key={folder.id} fs xs={10}>
                            <Name> {folder.folderName}</Name>
                        </GridFlexBox>
                        <GridFlexBox xs={2} fe>
                            <IconButton onClick={() => handleEdit(folder)}>
                                <DriveFileRenameOutlineIcon />
                            </IconButton>
                            <IconButton onClick={() => handleDelete(folder)}>
                                <DeleteIcon />
                            </IconButton>
                        </GridFlexBox>
                        <GridDivider />
                    </React.Fragment>
                ))}
            </Grid>
        </Container>
    );
}
