import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";

export default function loadAppointments(user, setAppointments) {
    try {
        const collectioRef = collection(db, "users", user.id, "appointments");
        const q = query(collectioRef, orderBy("date"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const appointments = mapSnapshot(snapshot);
            setAppointments(appointments);
            console.log(appointments);
        });
        return unsubscribe;
    } catch (error) {
        console.log(error);
    }
}
