import AttachFileIcon from "@mui/icons-material/AttachFile";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, IconButton, Tooltip, Typography, Chip } from "@mui/material";
import { collection, deleteDoc, doc, onSnapshot, orderBy, query, updateDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import ImageComponent from "../../components/Image/ImageComponent";
import UploadFilesButton from "../../components/UploadFile/UploadFilesButton";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { useInput } from "../../context-utils/InputContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import useColors from "../../hooks/useColors";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import MenuComponent from "./MenuComponent";

export default function ItemsComponent({ client }) {
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (!client) return;
        const collectionRef = collection(db, "clients", client.id, "items");
        const q = query(collectionRef, orderBy("updated", "desc"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const items = mapSnapshot(querySnapshot);
            console.log(items);
            setItems(items);
        });
        return unsubscribe;
    }, [client]);

    return (
        <Box>
            {items.map((item) => (
                <ItemComponent key={item.id} item={item} client={client} />
            ))}
        </Box>
    );
}

// Single Item Component
const ItemComponent = ({ item, client }) => {
    const confirmation = useConfirmation();
    const colors = useColors();

    const { user } = useContext(AuthContext);

    const handleDelete = async () => {
        const docRef = doc(db, "clients", client.id, "items", item.id);
        const response = await confirmation("Delete item", `Are you sure you want to delete ${item.name}?`);
        if (response) {
            await deleteDoc(docRef);
        }
    };

    const handleAddAttachment = async (files) => {
        console.log(files);
        const docRef = doc(db, "clients", client.id, "items", item.id);
        const newFiles = [];
        if (item.attachments) {
            newFiles.push(...item.attachments);
        }
        newFiles.push(...files);
        await updateDoc(docRef, { attachments: newFiles });
    };

    const input = useInput();

    const handleAddRemark = async () => {
        const response = await input("Add remark", "What remark do you want to add?", "Remark", "");
        if (response) {
            const docRef = doc(db, "clients", client.id, "items", item.id);
            const newRemarks = [];
            if (item.remarks) {
                newRemarks.push(...item.remarks);
            }
            newRemarks.push(response);
            await updateDoc(docRef, { remarks: newRemarks, updated: new Date(), updatedBy: user.id });
        }
    };

    const handleDeleteRemark = async (i) => {
        const docRef = doc(db, "clients", client.id, "items", item.id);
        const newRemarks = item.remarks.filter((_, index) => index !== i);
        await updateDoc(docRef, { remarks: newRemarks });
    };

    return (
        <Box
            key={item.id}
            minHeight={"80px"}
            p="8px"
            display="flex"
            alignItems={"center"}
            gap="8px"
            sx={{
                "&:hover": {
                    backgroundColor: colors.boxHighlight
                },
                "&:hover .itemIcons": {
                    display: "flex"
                },
                borderBottom: `1px solid ${colors.bottomBar}`
            }}
        >
            <Box display="flex" flexDirection={"column"} justifyContent="center" flex={1}>
                <Box display="flex" alignItems={"center"} gap="8px">
                    <Chip label={item.name} variant={"outlined"} />
                    <Box
                        className="itemIcons"
                        sx={{
                            display: "none"
                        }}
                    >
                        <UploadFilesButton
                            icon={<AttachFileIcon fontSize="small" />}
                            handleFile={handleAddAttachment}
                            toolTip="Add attachment"
                        />

                        <>
                            <Tooltip title={"Add remark"}>
                                <IconButton style={{ padding: "0 2px 0 2px" }} onClick={handleAddRemark}>
                                    <DriveFileRenameOutlineIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </>
                    </Box>
                </Box>
                {item.attachments?.map((attachment, i) => (
                    <SubItemComponent
                        key={`${attachment}${i}`}
                        attachment={attachment}
                        i={i}
                        item={item}
                        client={client}
                    />
                ))}
                {item.remarks?.map((remark, i) => (
                    <Box key={`${remark}${i}`} display="flex" alignItems={"center"}>
                        <Typography variant="caption">{remark}</Typography>
                        <MenuComponent handleDelete={handleDeleteRemark} client={i} />
                    </Box>
                ))}
            </Box>
            <Box>
                <MenuComponent handleDelete={handleDelete} client={item} />
            </Box>
        </Box>
    );
};

// Sub Item Component
const SubItemComponent = ({ attachment, item, client, i }) => {
    const confirmation = useConfirmation();

    const handleDelete = async (i) => {
        // confirm delete with confirmation dialog
        const response = await confirmation("Delete attachment", `Are you sure you want to delete ${attachment.name}?`);
        if (!response) return;

        const docRef = doc(db, "clients", client.id, "items", item.id);
        const newFiles = item.attachments.filter((_, index) => index !== i);
        await updateDoc(docRef, { attachments: newFiles });
    };

    return (
        <Box p="8px" display="flex" alignItems={"center"}>
            <ImageComponent image={attachment} i={i} handleDelete={handleDelete} width="100px" />
            <Typography variant="caption">{attachment.name || ""}</Typography>
        </Box>
    );
};
