import { Box } from "@mui/material";

function AudioPlayer({ downloadUrl }) {
    return (
        <Box
            sx={{
                // Add custom MUI styling here
                border: "1px solid",
                borderColor: "primary.main",
                borderRadius: 2,
                padding: 1,
                overflow: "hidden" // Prevents the controls from overflowing
            }}
        >
            <audio
                controls
                style={{
                    transform: "scale(0.75)", // Adjust the scale value to make it smaller
                    transformOrigin: "top left" // Keeps the player aligned to the top left corner
                }}
            >
                <source src={downloadUrl} />
                Your browser does not support the audio element.
            </audio>
        </Box>
    );
}

export default AudioPlayer;
