import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Box, Container, Grid, Icon, IconButton, Paper } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import LabelsList from "../../components/LabelsList";
import LeadsBoxHeader from "../../components/LeadsBoxHeader";
import { checkSelected } from "../../components/LeadsFlexBox";
import useList from "../../context-utils/ListContext";
import { StoreContext } from "../../context/StoreContext";
import convertDate from "../../functions/common-functions/convertDate";
import convertToNumber from "../../functions/common-functions/convertToNumber";
import updateField from "../../functions/database-functions/upadateField";
import { LEADS_HEADER } from "../../settings/settings";
import { GridContainer, GridDivider, GridFlexBox, Name } from "../../themes/themes";
import { checkItem } from "../LeadsTablePage/checkItem";
import createDropDownList from "../LeadsTablePage/createDropDownList";
import getMaxWidth from "../LeadsTablePage/getMaxWidth";
import loadPurchaseOrders from "../LeadsTablePage/loadPurchaseOrders";
import ColorPicker from "../../components/ColorPicker";
import getContrastColor from "../../utils-functions/getContrastColor";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import LeadActions from "../../components/LeadActions";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useRef } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import getCustomizeLeadHeader from "../../functions/getCustomizeLeadHeader";
import saveCustomizeLeadHeader from "../../functions/saveCustomizeLeadHeader";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

const COLLECTION_ID = "leads";

export default function LeadsTablePageV2({
    displayHeader,
    handleBack,
    page,
    totalPages,
    handleForward,
    displayLeads,
    leads,
    setPage,
    title,
    folder,
    setMaxNumber,
    maxNumber,
    searchAssign,
    setSearchAssign,
    searchLabel,
    setSearchLabel,
    showActions,
    setShowActions,
    search,
    setSearch,
    loading,
    followUpPage,
    select,
    colors,
    handleClickDate,
    handleClickSelect,
    user,
    leadsMode,
    handleClickLabel,
    isMobile,
    enableSelect,
    handleClickOpen,
    limitActions,
    setLead,
    setOpenCard,
    displayOpenCard,
    search2,
    setSearch2,
    sortByAssigned,
    setSortByAssigned,
    sortingOptions,
    setSortingOptions,
    setStartDate,
    setEndDate
}) {
    //to close header sorting columns when user click outside of pop up menu
    const popUpRef = useRef(null);
    const handleClickOutside = (event) => {
        if (popUpRef.current && !popUpRef.current.contains(event.target)) {
            setOpenColumns(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const [openColumns, setOpenColumns] = useState(false);
    const [checked, setChecked] = useState([]);

    const handleToggle = (value) => () => {
        // const currentIndex = checked.indexOf(value);

        const currentIndex = checked.findIndex((item) => item.label === value);
        console.log("currentIndex:", currentIndex);
        console.log("value:", value);

        let updatedArray = [...checked]; // Create a new array to avoid mutating the original array

        // Check if the index is within the bounds of the array
        if (currentIndex >= 0 && currentIndex < updatedArray.length) {
            // Toggle the display property at the specified index
            updatedArray[currentIndex] = {
                ...updatedArray[currentIndex], // Spread the existing object properties
                display: !updatedArray[currentIndex].display // Toggle the display property
            };

            // Now updatedArray[index].display is set to false
            console.log(updatedArray);
        } else {
            console.log("Invalid index provided");
        }
        console.log("after checked:", updatedArray);

        setChecked(updatedArray);
    };

    const handleHeaderMenu = () => {
        if (openColumns === true) {
            //if it is clicked when pop up open, will trigger save to db
        }
        setOpenColumns(!openColumns);
    };

    const [header, setHeader] = useState(LEADS_HEADER);

    useEffect(() => {
        //getArray from user setting
        //if exist, setHeader
        //else use LEADS_HEADER to save all into user setting

        const checkAndSetLeadHeaderV2 = async () => {
            //this function set header as object
            let result = await getCustomizeLeadHeader(user);

            if (result === null || result.length === 0) {
                let newHeader = header.map((item) => {
                    return {
                        ...item, // Spread the existing object properties
                        display: true // Add the new property
                    };
                });
                // saveCustomizeLeadHeader(user, newHeader);
                setChecked(newHeader);
            } else {
                setChecked(result);
            }
        };

        checkAndSetLeadHeaderV2();
    }, []);

    const [edit, setEdit] = useState({ headerId: "", value: "", poId: "", lead: {} });
    const [openColor, setOpenColor] = useState();

    //removed by wayne 14-3-24: collection not in use. There is not such collection as PurchaseOrders
    // const { setPurchaseOrders, addUnsubscribeStore } = useContext(StoreContext);

    // useEffect(() => {
    //     if (!leads) {
    //         const unsubscribe = loadPurchaseOrders(setPurchaseOrders);
    //         addUnsubscribeStore(unsubscribe);
    //     }
    // }, []);

    useEffect(() => {
        handleAutoWidth();
    }, [leads]);

    const handleAutoWidth = () => {
        if (!leads) return;
        const newHeader = [...header];
        newHeader.forEach((item) => {
            const maxWidth = getMaxWidth(leads, item, user);
            item.width = maxWidth;
        });
        setHeader(newHeader);
    };

    const list = useList();
    const handleClick = async (id) => {
        console.log(id);
        // SEARCH OR SORT
        const dropDownList = createDropDownList(leads, id, header, user);
        const response = await list(dropDownList, "Search", "name", "face", false, true);
        if (response) {
            setSearch2((prev) => {
                if (prev) return [...prev, { id, value: response.name, mode: "contain" }];
                return [{ id, value: response.name, mode: "contain" }];
            });
        }
    };

    const handleClickEdit = ({ headerId, poId: leadId, lead }) => {
        if (!lead.admins.includes(user.id)) return;

        const { type } = header.find((item) => item.id === headerId);

        if (type === "labels" || type === "color" || type === "actions" || type === "assignment") {
            return;
        }

        let value = checkItem(lead[headerId], type, user);
        if (type === "number") {
            value = lead[headerId];
        }
        setEdit({ headerId, poId: leadId, value, type: type || "text" });
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setEdit({ ...edit, value });
    };

    const handleBlur = async () => {
        console.log("Blur");
        setEdit({ headerId: "", poId: "", value: "", type: "" });
    };

    const handleSave = async () => {
        console.log("save");
        const docId = edit.poId;
        const collectionId = COLLECTION_ID;
        const field = edit.headerId;
        let value = edit.value;
        const oldPO = leads.find((po) => po.id === docId);

        // skip if no change
        if (value === checkItem(oldPO[field])) {
            setEdit({ headerId: "", poId: "", value: "" });
            return;
        }

        // check type
        if (edit.type === "date") {
            value = convertDate(value);
        }
        if (edit.type === "number") {
            value = convertToNumber(value);
        }
        await updateField({ docId, collectionId, field, value });
        setEdit({ headerId: "", poId: "", value: "", type: "" });
    };

    const handleKeyDown = (e, { i, j }) => {
        const { key } = e;
        if (key === "Tab") {
            e.preventDefault();
            if (j !== header.length - 1) {
                setEdit({
                    headerId: header[j + 1].id,
                    poId: displayLeads[i].id,
                    value: displayLeads[i][header[j + 1].id]
                });
            } else {
                if (i !== displayLeads.length - 1) {
                    setEdit({
                        headerId: header[0].id,
                        poId: displayLeads[i + 1].id,
                        value: displayLeads[i + 1][header[0].id]
                    });
                }
            }
        }
        if (key === "Enter") {
            e.preventDefault();
            handleSave();
        }
        if (key === "Escape") {
            e.preventDefault();
            setEdit({ headerId: "", poId: "", value: "" });
        }
    };

    const handleClickColor = (lead) => {
        setOpenColor(lead);
    };

    const handlePickColor = async (value) => {
        await updateField({ docId: openColor.id, collectionId: "leads", field: "color", value });
        setOpenColor();
    };

    const checkType = (item, lead, user) => {
        let type = item.type || "text";

        switch (type) {
            case "labels": {
                return (
                    <Box display={"flex"} justifyContent={"center"}>
                        <LabelsList labels={lead.labels} leadsMode={leadsMode} setSearchLabel={setSearchLabel} />
                        <IconButton size="small" sx={{ padding: 0 }} onClick={() => handleClickLabel(lead)}>
                            <Icon fontSize="small">add</Icon>
                        </IconButton>
                    </Box>
                );
            }
            case "color": {
                return (
                    <Box display={"flex"} justifyContent={"center"}>
                        <IconButton size="small" sx={{ padding: 0 }} onClick={() => handleClickColor(lead)}>
                            <Icon fontSize="small">add</Icon>
                        </IconButton>
                    </Box>
                );
            }
            case "actions": {
                return (
                    showActions && (
                        <>
                            <LeadActions
                                lead={lead}
                                leadsMode={leadsMode}
                                limit={3}
                                setLead={setLead}
                                setOpenCard={setOpenCard}
                            />
                        </>
                    )
                );
            }
            default: {
                return (
                    <Box display={"flex"} justifyContent={item.type === "number" ? "flex-end" : "center"}>
                        {/*modified by wayne 8-11-2023 
                        <Name nowrap fontFamily={"IBM Plex Sans"} ct>
                        change into wrap from nowrap for better display of assignments and admins cause of long string
                        */}
                        <Name wrap fontFamily={"IBM Plex Sans"} ct>
                            {checkItem(lead[item.id], type, user)}
                        </Name>
                    </Box>
                );
            }
        }
    };

    const handleClearSearch = (i) => {
        setSearch2((prev) => {
            if (prev) {
                const newSearch = [...prev];
                newSearch.splice(i, 1);
                return newSearch;
            } else {
                return [];
            }
        });
    };

    const handleSortUp = (index) => {
        const newArray = [...checked];
        if (index > 0) {
            // Swap the current element with the previous element
            [newArray[index - 1], newArray[index]] = [newArray[index], newArray[index - 1]];
        }
        setHeader(newArray);
        setChecked(newArray);
    };

    const handleSortDown = (index) => {
        const newArray = [...checked];

        if (index < newArray.length - 1) {
            // Swap the current element with the next element
            [newArray[index], newArray[index + 1]] = [newArray[index + 1], newArray[index]];
        }
        setHeader(newArray);
        setChecked(newArray);
    };

    useEffect(() => {
        //to save checked to db everytime user sort or add/remove field
        if (checked.length > 0) {
            saveCustomizeLeadHeader(user, checked);
        }
    }, [header, checked]);

    useEffect(() => {
        console.log(search);
    }, [search]);

    return (
        <Container maxWidth="none" disableGutters>
            <GridContainer>
                {displayHeader && (
                    <GridFlexBox>
                        {search}
                        <LeadsBoxHeader
                            handleBack={handleBack}
                            page={page}
                            totalPages={totalPages}
                            handleForward={handleForward}
                            displayLeads={displayLeads}
                            leads={leads}
                            setPage={setPage}
                            title={title}
                            folder={folder}
                            setMaxNumber={setMaxNumber}
                            maxNumber={maxNumber}
                            searchAssign={searchAssign}
                            setSearchAssign={setSearchAssign}
                            searchLabel={searchLabel}
                            setSearchLabel={setSearchLabel}
                            showActions={showActions}
                            setShowActions={setShowActions}
                            search={search}
                            setSearch={setSearch}
                            sortByAssigned={sortByAssigned}
                            setSortByAssigned={setSortByAssigned}
                            setSearch2={setSearch2}
                            user={user}
                            sortingOptions={sortingOptions}
                            setSortingOptions={setSortingOptions}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </GridFlexBox>
                )}
                {!arrayIsEmpty(search2) &&
                    search2.map((s, i) => (
                        <GridFlexBox xs={12} md={12} key={i}>
                            {s.id && (
                                <>
                                    <Name ct>Search ID: </Name>
                                    <Name ct bold pl1>
                                        {s.id}
                                    </Name>
                                    <Name ct pl1>
                                        Search mode:{" "}
                                    </Name>
                                    <Name ct bold pl1>
                                        {s.mode}
                                    </Name>
                                    <Name ct pl1>
                                        Search value:{" "}
                                    </Name>
                                    <Name ct bold pl1>
                                        {s.value}
                                    </Name>
                                    <IconButton size="small" onClick={() => handleClearSearch(i)}>
                                        <HighlightOffIcon />
                                    </IconButton>
                                </>
                            )}
                        </GridFlexBox>
                    ))}
                <GridDivider />
                {/*sorting columns menu*/}
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                    <div
                        ref={popUpRef}
                        style={{ position: "relative", left: "0", paddingBottom: "10px", paddingLeft: "10px" }}
                    >
                        <Button
                            variant="outlined"
                            endIcon={<ArrowDropDownIcon />}
                            id="basic-button"
                            onClick={handleHeaderMenu}
                        >
                            Columns
                        </Button>
                        {openColumns && (
                            <List
                                sx={{
                                    minWidth: "250px", // Set the width to 80% of the parent container
                                    bgcolor: "#999696",
                                    maxHeight: "256px", // Equivalent to 5 items height, adjust as needed
                                    overflowY: "auto", // Enable vertical scrollbar if content exceeds maxHeight
                                    position: "absolute", // or 'fixed' if you want it to stay in the same position while scrolling
                                    top: "calc(100% + 10px)", // Position the list just below the button with a 10px gap
                                    left: 0,
                                    zIndex: 9999 // Set a high zIndex value to float the list on top of everything
                                }}
                            >
                                {checked?.map((value, index) => {
                                    let labelId = value.label;
                                    return (
                                        <ListItem
                                            key={`${labelId}-${index}`} // Modified to ensure uniqueness
                                            disablePadding
                                            secondaryAction={
                                                <>
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="comments"
                                                        onClick={() => handleSortUp(index)}
                                                    >
                                                        <KeyboardDoubleArrowUpIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="comments"
                                                        onClick={() => handleSortDown(index)}
                                                    >
                                                        <KeyboardDoubleArrowDownIcon />
                                                    </IconButton>
                                                </>
                                            }
                                        >
                                            <ListItemButton onClick={handleToggle(labelId)} dense key={labelId}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={
                                                            checked.some(
                                                                (item) => item.label === labelId && item.display
                                                            ) || false
                                                        }
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={labelId} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                    </div>
                </div>
                <Paper style={{ overflow: "auto", height: "67vh" }} variant="outlined">
                    {/* HEADERS */}
                    <Grid item xs={12} flexWrap="nowrap">
                        <Box display={"flex"}>
                            <Box
                                sx={{
                                    width: "50px",
                                    padding: "4px",
                                    border: "1px solid grey"
                                }}
                                flexShrink={0}
                                alignSelf={"stretch"}
                                display={"flex"}
                            >
                                {enableSelect && (
                                    // <GridFlexBox>
                                    //     <IconButton size={"small"} style={{ padding: 0 }} disabled>
                                    //         <CheckBoxOutlineBlankIcon />
                                    //         <IconButton size="small" sx={{ padding: 0, paddingLeft: "4px" }} disabled>
                                    //             <OpenInNewIcon />
                                    //         </IconButton>
                                    //     </IconButton>
                                    // </GridFlexBox>
                                    // finetune by wayne 14-3-24: to remove button error
                                    <GridFlexBox>
                                        <IconButton size={"small"} style={{ padding: 0 }} disabled>
                                            <CheckBoxOutlineBlankIcon />
                                        </IconButton>
                                        <IconButton size="small" sx={{ padding: 0, paddingLeft: "4px" }} disabled>
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </GridFlexBox>
                                )}
                            </Box>
                            {/* this part is to retrieve customize header from db users.setting.customizeHeader*/}
                            {checked?.map((item) => {
                                if (item.display === true) {
                                    return (
                                        <Box
                                            sx={{
                                                width: item.width || "100px",
                                                padding: "4px",
                                                border: "1px solid grey",
                                                borderLeft: "none"
                                            }}
                                            key={item.id}
                                            flexShrink={0}
                                            alignSelf={"stretch"}
                                        >
                                            <GridFlexBox>
                                                <Name bold cp onClick={() => handleClick(item.id)}>
                                                    {item.label}
                                                </Name>
                                            </GridFlexBox>
                                        </Box>
                                    );
                                }
                                return null;
                            })}
                        </Box>
                    </Grid>

                    {/* ROWS OF DATA */}

                    {displayLeads?.map((lead, i) => {
                        return displayRow(
                            enableSelect,
                            handleClickSelect,
                            select,
                            handleClickOpen,
                            header,
                            edit,
                            handleChange,
                            handleBlur,
                            handleKeyDown,
                            handleSave,
                            handleClickEdit,
                            checkType,
                            user,
                            lead,
                            i,
                            checked
                        );
                    })}
                </Paper>

                <ColorPicker open={openColor} setOpen={setOpenColor} onClose={handlePickColor} />
            </GridContainer>
        </Container>
    );
}

const isEdit = (headerId, poId, edit) => {
    if (headerId === edit.headerId && poId === edit.poId) return true;
    return false;
};

function displayRow(
    enableSelect,
    handleClickSelect,
    select,
    handleClickOpen,
    header,
    edit,
    handleChange,
    handleBlur,
    handleKeyDown,
    handleSave,
    handleClickEdit,
    checkType,
    user,
    lead,
    i,
    checked
) {
    const component = (lead, i, assigned = false) => {
        return (
            <>
                <Grid
                    item
                    flexWrap="nowrap"
                    key={lead.id}
                    // display={"inline-block"}
                    display="flex"
                    sx={{
                        backgroundColor: lead.color || false,
                        color: lead.color ? getContrastColor(lead.color) : false
                    }}
                >
                    <Box display={"flex"} alignItems="center">
                        {/* lead card icon*/}
                        <Box
                            sx={{
                                width: "50px",
                                padding: "4px",
                                border: "1px solid grey",
                                borderTop: "none"
                            }}
                            flexShrink={0}
                            alignSelf={"stretch"}
                        >
                            {enableSelect && (
                                <GridFlexBox>
                                    <IconButton
                                        size={"small"}
                                        disabled={assigned}
                                        style={{ padding: 0 }}
                                        onClick={() => handleClickSelect(lead)}
                                    >
                                        {checkSelected(lead, select)}
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        sx={{ padding: 0, paddingLeft: "4px" }}
                                        onClick={() => handleClickOpen(lead)}
                                    >
                                        <OpenInNewIcon />
                                    </IconButton>
                                </GridFlexBox>
                            )}
                        </Box>
                        {checked?.map((item, j) => {
                            if (item.display === true) {
                                return (
                                    <Box
                                        sx={{
                                            width: item.width || "100px",
                                            padding: "4px",
                                            border: "1px solid grey",
                                            borderLeft: "none",
                                            borderTop: "none"
                                        }}
                                        alignSelf={"stretch"}
                                        key={item.id}
                                        flexShrink={0}
                                    >
                                        {isEdit(item.id, lead.id, edit) ? (
                                            <GridFlexBox fs>
                                                <Paper style={{ zIndex: 20000 }}>
                                                    <input
                                                        value={edit.value || ""}
                                                        type={edit.type === "date" ? "date" : "text"}
                                                        style={{
                                                            padding: "8px",
                                                            border: 0,
                                                            fontFamily: "IBM Plex Sans",
                                                            zIndex: 1000
                                                        }}
                                                        onChange={handleChange}
                                                        autoFocus={true}
                                                        onBlur={handleBlur}
                                                        onKeyDown={(e) => handleKeyDown(e, { i, j })}
                                                    />
                                                    <IconButton onMouseDown={handleSave} style={{ zIndex: 10000 }}>
                                                        <SaveOutlinedIcon style={{ zIndex: 10000 }} />
                                                    </IconButton>
                                                </Paper>
                                            </GridFlexBox>
                                        ) : (
                                            <Grid
                                                item
                                                onClick={() =>
                                                    handleClickEdit({ headerId: item.id, poId: lead.id, lead })
                                                }
                                            >
                                                {checkType(item, lead, user)}
                                            </Grid>
                                        )}
                                    </Box>
                                );
                            }
                        })}
                    </Box>
                </Grid>
            </>
        );
    };

    return (
        <React.Fragment>
            {component(lead, i)}
            {lead.assignedLeads?.map((lead, i) => component(lead, i, true))}
        </React.Fragment>
    );
}
