import FullDialog from "../../components/FullDialog";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import PDFFileUploader from "../../components/PDFFileUploader";
import { purple } from '@mui/material/colors';

const UploadSQLFile = ({
    user
}) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Box m={"8px"}>
                <Button
                    variant="contained"
                    onClick={() => setOpen(true)}
                    sx={{backgroundColor: purple[500]}}
                >
                    Upload PDF
                </Button>
                <FullDialog
                    Component={
                        <Box m={"8px"}>
                            <PDFFileUploader user={user} setOpen={setOpen}/>
                        </Box>
                    }
                    open={open}
                    handleClose={() => setOpen(false)}
                    title="Upload SQL PDF"
                />
            </Box>
        </>
    );
}

export default UploadSQLFile;