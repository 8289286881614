import { Container } from "@mui/system";
import { ButtonC, GridContainer, GridDivider, GridFlexBox, Title } from "../themes/themes";
import { getLongLivedUserToken } from "../functions/getLongLivedUserToken";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import saveToken from "../functions/saveToken";

export default function FacebookLoginPage() {
    const { user } = useContext(AuthContext);

    const handleResponse = async (response) => {
        const shortToken = response.authResponse?.accessToken;
        const longToken = await getLongLivedUserToken(shortToken);
        await saveToken(longToken, user);
    };

    const handleClick = () => {
        window.FB.login(handleResponse, {
            scope: "public_profile, email, leads_retrieval, pages_show_list, whatsapp_business_management"
        });
    };

    return (
        <Container>
            <GridContainer>
                <GridFlexBox>
                    <Title>Facebook Login</Title>
                </GridFlexBox>
                <GridDivider />
                <GridFlexBox>
                    <ButtonC onClick={handleClick}>Login with Facebook</ButtonC>
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
