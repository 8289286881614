import { Box, Button, Container, Icon } from "@mui/material";
import { orange } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { ButtonC, GridContainer, GridDivider, GridFlexBox } from "../../themes/themes";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import ListingFileCard from "./ListingFileCard";
import ListingFilesGallery from "./ListingFilesGallery";
import SelectFiles from "../../components/UploadFile/SelectFilesComponent";

export default function UploadListingDocument({handleFile, handleClickExistingFile,currentListing }) {
    const [files, setFiles] = useState([]);
    const [filesRef, setFilesRef] = useState();
    const [startUpload, setStartUpload] = useState(false);

    useEffect(() => {
        if (!filesRef) return;
        console.log(filesRef);
    }, [filesRef]);

    const filesRefArray = [];

    const handleClickSelect = (selectedFiles) => {
        const newFiles = [...files, ...selectedFiles];
        setFiles(newFiles);
    };

    const handleClickUpload = async () => {
        if (arrayIsEmpty(files)) {
            return;
        }

        setStartUpload(true);
    };

    const handleDelete = (i) => {
        const newFiles = [...files];
        newFiles.splice(i, 1);
        setFiles(newFiles);
    };

    const returnFile = async (fileRef, i) => {
        filesRefArray.push({ ...fileRef, i });
        if (filesRefArray.length === files.length) {
            filesRefArray.sort((a, b) => a.i - b.i);
            setFilesRef(filesRefArray);
            handleFile(filesRefArray);
        }
    };

    const handleSave = () => {
        handleFile(filesRef);
    };

    const handleClick = (file) => {
        handleClickExistingFile(file);
    };

    return (
        <Container maxWidth={"md"}>
            <Box m={1} alignItems="center">
                <GridContainer>
                    {files.map((file, i) => (
                        <ListingFileCard
                            key={i}
                            i={i}
                            file={file}
                            handleDelete={handleDelete}
                            startUpload={startUpload}
                            returnFile={returnFile}
                        />
                    ))}
                    <GridFlexBox mt8>
                        <SelectFiles handleClickSelect={handleClickSelect} startUpload={startUpload} />
                    </GridFlexBox>
                    <GridFlexBox mt8>
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={handleClickUpload}
                            disabled={arrayIsEmpty(files) || startUpload}
                        >
                            Upload Files
                        </Button>
                        {!arrayIsEmpty(files) && (
                            <Icon color="error" fontSize="large">
                                arrow_circle_left
                            </Icon>
                        )}
                    </GridFlexBox>
                    <GridDivider />
                    <GridFlexBox mt4>
                        <ButtonC disabled={!filesRef} bgColor={orange[500]} onClick={handleSave}>
                            Save
                        </ButtonC>
                    </GridFlexBox>
                    <GridDivider />
                    <ListingFilesGallery handleClick={handleClick} currentListing={currentListing} />
                </GridContainer>
            </Box>
        </Container>
    );
}
