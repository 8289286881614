import DeleteIcon from "@mui/icons-material/Delete";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Collapse, Container, IconButton, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import useNotification from "../context-utils/NotificationContext";
import { AuthContext } from "../context/AuthContext";
import { StoreContext } from "../context/StoreContext";
import addFollowUp from "../functions/addFollowUp";
import deleteFollowUp from "../functions/deleteFollowUp";
import loadFollowUps from "../functions/loadFollowUps";
import { ButtonC, ChipC, GridContainer, GridDivider, GridFlexBox, Loading, Name, Title } from "../themes/themes";
import cfDate from "../utils-functions/cfDate";
import formatDate from "../utils-functions/formatDate";
import getDay from "../utils-functions/getDay";

const DATE = formatDate(new Date(), true);
export default function ManageFollowUp({ lead, handleClose }) {
    const [date, setDate] = useState(DATE);
    const [remarks, setRemarks] = useState("");
    const { user } = useContext(AuthContext);
    const { followUps, setFollowUps, addUnsubscribeStore } = useContext(StoreContext);
    const [loading, setLoading] = useState(false);
    const [followUpData, setFollowUpData] = useState();
    const [edit, setEdit] = useState(false);

    const notification = useNotification();

    useEffect(() => {
        let unsubscribe = () => {};
        if (!followUps) {
            console.log("loadFollowUps");
            unsubscribe = loadFollowUps(user, setFollowUps);
            addUnsubscribeStore(unsubscribe);
        }
    }, []);

    const handleAddFollowUp = async () => {
        if (!remarks) {
            notification("Please add remarks", "warning");
            return;
        }
        let followUpData = {
            user: user.id,
            created: new Date(),
            updated: new Date(),
            clientName: lead.name || "",
            clientEmail: lead.email || "",
            clientPhone: lead.phone || "",
            status: "PENDING",
            leadId: lead.id,
            leadOwner: lead.ownerEmail || "",
            date: new Date(date),
            remarks
        };
        setLoading(true);
        const followUpId = await addFollowUp(user, followUpData);
        followUpData = { ...followUpData, id: followUpId };
        setLoading(false);
        setFollowUpData(followUpData);
    };

    const handleSave = () => {
        handleClose({ date, followUpId: followUpData.id, remarks: followUpData.remarks });
    };

    const handleChange = (e) => {
        setRemarks(e.target.value);
    };

    const handleDelete = async (followUp) => {
        await deleteFollowUp(followUp);
    };

    return (
        <Container maxWidth="md" disableGutters>
            <GridContainer>
                <GridFlexBox gap={1}>
                    <Title>Follow up manager</Title>
                </GridFlexBox>

                <Loading loading={loading} />

                <GridDivider />
                {lead && (
                    <>
                        <GridFlexBox>
                            <Name ct bold>
                                {lead?.name}
                            </Name>
                        </GridFlexBox>
                        <GridDivider />
                    </>
                )}

                <GridFlexBox pl1 xs={8}>
                    <TextField
                        autoFocus={false}
                        fullWidth
                        type="datetime-local"
                        onChange={(e) => setDate(e.target.value)}
                        value={date}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </GridFlexBox>

                <GridFlexBox xs={4}>
                    <ButtonC onClick={handleAddFollowUp}>Add date</ButtonC>
                </GridFlexBox>

                <GridFlexBox pl1 xs={8}>
                    <TextField onChange={handleChange} value={remarks} label="remarks" fullWidth required />
                </GridFlexBox>

                <GridFlexBox xs={4}>
                    <ButtonC color="warning" onClick={handleSave} disabled={!followUpData}>
                        Save
                    </ButtonC>
                </GridFlexBox>

                <GridDivider />

                <GridFlexBox fs pl1 xs={10}>
                    <Name>Existing Follow Ups</Name>
                </GridFlexBox>

                <GridFlexBox fe xs={2}>
                    <IconButton onClick={() => setEdit((e) => !e)}>
                        {edit ? <DisabledByDefaultOutlinedIcon /> : <EditOutlinedIcon />}
                    </IconButton>
                </GridFlexBox>

                <GridDivider />

                <FollowUpList followUps={followUps} edit={edit} handleDelete={handleDelete} />
            </GridContainer>
        </Container>
    );
}

function FollowUpList({ followUps, edit, handleDelete }) {
    return (
        <TransitionGroup component={null}>
            {followUps?.map((followUp) => (
                <Collapse key={followUp.id} in={true} style={{ width: "100%" }}>
                    <GridContainer pl1 pr1>
                        {edit && (
                            <GridFlexBox fe>
                                <IconButton
                                    onClick={() => handleDelete(followUp)}
                                    size="small"
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                >
                                    <DeleteIcon color="warning" />
                                </IconButton>
                            </GridFlexBox>
                        )}

                        <GridFlexBox fs w="50%">
                            <Name bold>{followUp.clientName}</Name>
                        </GridFlexBox>

                        <GridFlexBox fe w={"50%"} gap={1}>
                            <ChipC color={getDay(followUp.date).color}>{getDay(followUp.date).label}</ChipC>
                            <Name>{cfDate(followUp.date)}</Name>
                        </GridFlexBox>

                        <GridFlexBox fs w="50%">
                            <Name>{followUp.clientPhone}</Name>
                        </GridFlexBox>

                        <GridFlexBox fe w="50%">
                            <Name>{followUp.remarks}</Name>
                        </GridFlexBox>

                        <GridDivider />
                    </GridContainer>
                </Collapse>
            ))}
        </TransitionGroup>
    );
}
