import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import removeSpecialChars from "../../functions/common-functions/removeSpecialChars";
import mapSnapshot from "../../utils-functions/mapSnapshot";

export default function loadAssignedNumbers(user, setAssignedNumbers, setLoading) {
    try {
        const collectionRef = collection(db, "whatsappFlowAssignedNumbers");
        const q = query(collectionRef, where("clientId", "==", removeSpecialChars(user.id)), orderBy("date", "desc"));
        setLoading(true);
        return onSnapshot(q, (querySnapshot) => {
            const assignedNumbers = mapSnapshot(querySnapshot);
            console.log(assignedNumbers);
            setAssignedNumbers(assignedNumbers);
            setLoading(false);
        });
    } catch (error) {
        console.log(error);
    }
}
