import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function updateFlowStatus(user, flow, newStatus) {
    try {
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id);
        await updateDoc(docRef, { status: newStatus });
        console.log("Statud updated");
    } catch (error) {
        console.log(error);
    }
}
