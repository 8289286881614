import DeleteIcon from "@mui/icons-material/Delete";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Button, Dialog, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { addDoc, collection, deleteDoc, doc, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import * as React from "react";
import { toast } from "react-hot-toast";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { useInput } from "../../context-utils/InputContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import ProfilePicture from "./ProfilePicture";
import useColors from "../../hooks/useColors";

export default function CompanyMenu({ setCurrentCompany_, clients, setCurrentClientId }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [companies, setCompanies] = React.useState([]);
    const [currentCompany, setCurrentCompany] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);

    const { user } = React.useContext(AuthContext);

    React.useEffect(() => {
        if (!user) return;
        const collectionRef = collection(db, "companies");
        const q = query(collectionRef, where("admins", "array-contains", user.id));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const companies = mapSnapshot(querySnapshot);
            setCompanies(companies);
        });
        return unsubscribe;
    }, [user]);

    React.useEffect(() => {
        if (!currentCompany) return;
        const newCompany = companies.find((company) => company.id === currentCompany.id);
        if (!newCompany) return;
        setCurrentCompany(newCompany);
        setCurrentCompany_(newCompany);
    }, [companies]);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const input = useInput();

    const handleAddCompany = async () => {
        const collectionRef = collection(db, "companies");
        const response = await input("Add Company", "What is your company name?", "Name", "");
        if (response) {
            const company = { name: response, admins: [user.id], created: new Date() };
            const ref = await addDoc(collectionRef, company);
            toast.success("Company added");
            setCurrentCompany({ ...company, id: ref.id });
            setCurrentCompany_({ ...company, id: ref.id });
        }
    };

    const handleClickCompany = (company) => {
        setCurrentCompany(company);
        setCurrentCompany_(company);
        setCurrentClientId(null);
    };

    const handleClickSettings = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const confirmation = useConfirmation();

    const handleDeleteCompany = async () => {
        if (!currentCompany) return;
        if (clients.length > 0) return toast.error("You can't delete a company with clients");

        const response = await confirmation(
            "Delete company",
            `Are you sure you want to delete ${currentCompany.name}?`
        );
        if (response) {
            const docRef = doc(db, "companies", currentCompany.id);
            await deleteDoc(docRef);
            toast.success("Company deleted");
            setCurrentCompany(null);
            setCurrentCompany_(null);
            setOpenDialog(false);
        }
    };

    const handleSaveFile = async (file) => {
        if (!currentCompany) return;
        const ref = doc(db, "companies", currentCompany.id);
        await updateDoc(ref, { profilePicture: file });
        toast.success("Profile picture updated");
    };

    const colors = useColors();

    return (
        <React.Fragment>
            <Box
                gap="8px"
                p="8px"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "left",
                    cursor: "pointer",
                    background: colors.textBoxBackgroundClient,
                    borderRadius: "6px",
                    "&:hover": {
                        backgroundColor: colors.boxHighlight
                    }
                }}
                onClick={handleClick}
                width={"100%"}
            >
                <ProfilePicture data={currentCompany?.profilePicture || {}} size={24} />
                <Typography variant="caption" sx={{ flex: 1 }}>
                    {currentCompany ? currentCompany.name : "No company selected"}
                </Typography>
                <UnfoldMoreIcon size="small" color="inherit" />
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1
                            },
                            "&::before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                left: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0
                            }
                        }
                    }
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            >
                {companies.map((company) => (
                    <MenuItem onClick={() => handleClickCompany(company)} key={company.id}>
                        <ProfilePicture data={company?.profilePicture || {}} size={32} />
                        <Typography variant="caption">{company.name}</Typography>
                    </MenuItem>
                ))}

                {companies.length === 0 && (
                    <MenuItem disabled>
                        <Avatar /> no companies
                    </MenuItem>
                )}

                <Divider />
                <MenuItem onClick={handleAddCompany}>
                    <ListItemIcon>
                        <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    Add another company
                </MenuItem>
                <MenuItem onClick={handleClickSettings} disabled={!currentCompany}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
            </Menu>
            <Dialog
                onClose={handleCloseDialog}
                open={openDialog}
                sx={{
                    // Ensure dialog has no unnecessary internal spacing that might cause issues
                    "& .MuiDialog-paper": {
                        maxWidth: { xs: "100%", md: "60%" },
                        width: { xs: "100%", md: "60%" },
                        maxHeight: { xs: "80%", md: "60%" },
                        height: { xs: "80%", md: "60%" }
                    }
                }}
            >
                <Box display="flex" p="8px" flexDirection={"column"} gap="8px" width={"100%"}>
                    <Box display="flex" alignItems={"center"} gap="8px" width={"100%"}>
                        <ProfilePicture
                            edit={true}
                            saveFile={handleSaveFile}
                            data={currentCompany?.profilePicture || {}}
                        />
                        <Typography flex={1}>{currentCompany?.name || ""}</Typography>
                    </Box>

                    <Divider />

                    <Box width={"100%"}>
                        <Button color="error" onClick={handleDeleteCompany} size="small">
                            <Typography sx={{ marginRight: "8px" }}>Delete Company</Typography>
                            <DeleteIcon color="error" />
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </React.Fragment>
    );
}
