import { Button } from "@mui/material";
import React, { useMemo } from "react";
import * as XLSX from "xlsx";

function ExportToExcel({ data, fieldToDisplay }) {
    const displayData = useMemo(() => {
        if (data && fieldToDisplay) {
            return data.map((item) => {
                let obj = {};
                fieldToDisplay.forEach((field) => {
                    obj[field] = item[field];
                });
                return obj;
            });
        }
        return [];
    }, [fieldToDisplay, data]);
    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(displayData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "data.xlsx");
    };

    return (
        <Button variant="outlined" size="small" onClick={downloadExcel}>
            Download Excel
        </Button>
    );
}

export default ExportToExcel;
