import React from "react";
import { IconButton, Dialog, Box } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function SortDialog({ sort = "date", setSort }) {
    const [open, setOpen] = React.useState(false);

    const handleChange = (event) => {
        setSort(event.target.value);
        setOpen(false);
    };

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <FilterListIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                sx={{}}
                PaperProps={{
                    sx: {
                        width: {
                            xs: "95vw", // width for extra-small screens
                            sm: "300px" // width for medium screens and above
                        }
                    }
                }}
            >
                <Box m="16px">
                    <FormControl>
                        <FormLabel>Sort by</FormLabel>
                        <RadioGroup value={sort} onChange={handleChange}>
                            <FormControlLabel value="date" control={<Radio />} label="date" />
                            <FormControlLabel value="unread" control={<Radio />} label="Unread" />
                            <FormControlLabel value="unreadAI" control={<Radio />} label="Unread AI" />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Dialog>
        </>
    );
}
