import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Box } from "@mui/material";
import { useMemo } from "react";
import UploadFilesButton from "../../components/UploadFile/UploadFilesButton";

export default function ProfilePicture({ data = {}, saveFile = () => {}, edit = false, size = 64 }) {
    const handleFile = (files) => {
        console.log(files);
        saveFile(files[0]);
    };

    const url = useMemo(() => {
        if (data?.thumbnailURL) return data.thumbnailURL;
        if (data?.downloadURL) return data.downloadURL;
        return "";
    }, [data]);

    return (
        <Box
            sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                position: "relative",
                "&:hover .editButton": {
                    display: "block" // Show the button on hover
                }
            }}
        >
            <Avatar src={url} sx={{ width: size, height: size }} />
            {edit && (
                <Box
                    className="editButton"
                    sx={{
                        display: "none", // Hide the button by default
                        position: "absolute", // Position it relative to the avatar container
                        right: 0, // Adjust these as needed to position the button
                        top: 0 // Adjust these as needed to position the button
                    }}
                >
                    <UploadFilesButton handleFile={handleFile} icon={<EditIcon />} />
                </Box>
            )}
        </Box>
    );
}
