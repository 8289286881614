import { where } from "@firebase/firestore";
import { collection, limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";

export default function loadChatRooms(setChatRooms, clientId, chatRoomLimit, source) {
    try {
        const collectionRef = collection(db, "whatsappMessages");
        let q;

        if (source === 1) { //which means want to fetch the chatroom people shared with this user id
            console.log("entered source 1")
            q = query(collectionRef, where("sharedWithId", "array-contains", clientId), orderBy("date", "desc"), limit(chatRoomLimit));
            console.log("q: ", q)
        } else { //by default just get your own chatroom
            console.log("entered source not 1")
            q = query(collectionRef, where("clientId", "==", clientId), orderBy("date", "desc"), limit(chatRoomLimit));
            console.log("q: ", q)
        }

        return onSnapshot(q, (snapshot) => {
            const data = mapSnapshot(snapshot);
            setChatRooms(data);
        });
    } catch (err) {
        console.log(err);
    }
}
