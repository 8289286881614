import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    Button,
    Divider,
    IconButton,
    Input,
    InputAdornment,
    Typography
} from "@mui/material";
import React, { useContext, useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";

import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { useInput } from "../../context-utils/InputContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import useColors from "../../hooks/useColors";
import useWindowSize from "../../hooks/useWindowSize";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import CompanyMenu from "./CompanyMenu";
import EditableFieldComponent from "./EditableFieldComponent";
import ItemsComponent from "./ItemsComponent";
import MenuComponent from "./MenuComponent";
import ProfilePicture from "./ProfilePicture";

export default function ClientsPage() {
    const [currentCompany, setCurrentCompany] = React.useState(null);
    const [clients, setClients] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const [currentClient, setCurrentClient] = React.useState(null);
    const [currentClientId, setCurrentClientId] = React.useState(null);
    const [selectClients, setSelectClients] = React.useState(true);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (!currentCompany) return;
        const collectionRef = collection(db, "clients");
        const q = query(collectionRef, where("company", "==", currentCompany.id), orderBy("updated", "desc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const clients = mapSnapshot(querySnapshot);
            setClients(clients);
        });
        return unsubscribe;
    }, [currentCompany]);

    useEffect(() => {
        if (!currentClientId) {
            setCurrentClient(null);
            return;
        }

        // Get a reference to the single document
        const docRef = doc(db, "clients", currentClientId);

        // Listen to real-time updates on the document
        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                // If the document exists, update the state with the new data
                setCurrentClient({
                    id: docSnapshot.id,
                    ...docSnapshot.data()
                });
            } else {
                // Handle the case where the document does not exist
                console.log("Document not found!");
            }
        });

        // Clean up the listener when the component unmounts or the dependency changes
        return unsubscribe;
    }, [currentClientId]);

    const colors = useColors();

    const handleAddClient = async () => {
        const collectionRef = collection(db, "clients");

        if (search) {
            const client = {
                name: search,
                company: currentCompany.id,
                created: new Date(),
                updated: new Date(),
                admins: [user.id]
            };
            const response = await addDoc(collectionRef, client);
            toast.success("Client added");
            setCurrentClientId(response.id);
            setSearch("");
        }
    };

    const saveProfilePicture = async (file) => {
        if (!currentClient) return;
        const docRef = doc(db, "clients", currentClient.id);
        await updateDoc(docRef, { profilePicture: file });
        toast.success("Profile picture saved");
    };

    const windowSize = useWindowSize();

    const boxWidth = useMemo(() => {
        if (windowSize.width < 600) {
            // Mobile View
            if (selectClients) {
                const leftWidth = "100%";
                const rightWidth = 0;
                return { leftWidth, rightWidth };
            } else {
                const leftWidth = 0;
                const rightWidth = "100%";
                return { leftWidth, rightWidth };
            }
        } else {
            // Desktop View
            const leftWidth = "30%";
            const rightWidth = "70%";
            return { leftWidth, rightWidth };
        }
    }, [windowSize, selectClients]);

    const confirmation = useConfirmation();

    const handleDeleteClient = async (client) => {
        console.log(client);

        const response = await confirmation("Delete client", `Are you sure you want to delete ${client.name}?`);
        if (response) {
            const docRef = doc(db, "clients", client.id);
            await deleteDoc(docRef);
            toast.success("Client deleted");
        }
    };

    const input = useInput();

    const handleAddItem = async () => {
        const collectionRef = collection(db, "clients", currentClient.id, "items");

        const response = await input("Add new item", "What is your item name?", "Name", "");

        if (response) {
            const item = {
                name: response,
                created: new Date(),
                updated: new Date(),
                createdBy: user.id
            };

            const ref = await addDoc(collectionRef, item);
            console.log("Item added ", ref.id);
        }
    };

    return (
        <Box display="flex" width="100%" height={"calc(100vh - 55px)"}>
            <Box
                display={boxWidth.leftWidth ? "flex" : "none"}
                flexDirection={"column"}
                width={boxWidth.leftWidth}
                height="100%"
            >
                <Box
                    display="flex"
                    p="8px"
                    gap="8px"
                    sx={{ background: colors.topBar, borderRight: `1px solid ${colors.topBarLine}` }}
                >
                    <Box display="flex" alignItems={"center"} gap="4px" width="100%">
                        <CompanyMenu
                            setCurrentCompany_={setCurrentCompany}
                            clients={clients}
                            setCurrentClientId={setCurrentClientId}
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    width={"100%"}
                    p="8px"
                    gap="4px"
                    sx={{
                        borderBottom: `1px solid ${colors.topBarLine}`,
                        borderRight: `1px solid ${colors.topBarLine}`
                    }}
                >
                    <Input
                        disableUnderline
                        fullWidth
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        sx={{
                            background: colors.searchBox,
                            borderRadius: "6px",
                            height: "35px",
                            padding: "0 8px 0 8px"
                        }}
                        startAdornment={
                            <InputAdornment position="start">
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <IconButton onClick={handleAddClient} disabled={!currentCompany}>
                        <AddIcon />
                    </IconButton>
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Box>
                <Box
                    display="flex"
                    flex={1}
                    height={"100%"}
                    flexDirection={"column"}
                    sx={{ overflowY: "auto", background: colors.clientBar }}
                >
                    {clients.map((client) => (
                        <Box
                            key={client.id}
                            display="flex"
                            alignItems={"center"}
                            gap="8px"
                            width="100%"
                            p="8px"
                            onClick={() => {
                                setSelectClients(false);
                                setCurrentClientId(client.id);
                            }}
                            sx={{
                                cursor: "pointer",
                                background: colors.clientBar,
                                borderBottom: `1px solid ${colors.topBarLine}`,
                                "&:hover": {
                                    backgroundColor: colors.boxHighlight
                                }
                            }}
                        >
                            <ProfilePicture data={client?.profilePicture} size={48} />
                            <Box display="flex" flex={1}>
                                <Typography variant="body2">{client?.name || ""}</Typography>
                            </Box>
                            <MenuComponent handleDelete={handleDeleteClient} client={client} />
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box
                display={boxWidth.rightWidth ? "flex" : "none"}
                flex={1}
                flexDirection={"column"}
                width={boxWidth.rightWidth}
                height={"100%"}
            >
                <Box
                    display="flex"
                    sx={{ backgroundColor: colors.topBar }}
                    width="100%"
                    gap="8px"
                    height="55px"
                    alignItems="center"
                    p="8px"
                >
                    {windowSize.width < 600 && (
                        <IconButton size="small" onClick={() => setSelectClients(true)}>
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                    <ProfilePicture data={currentClient?.profilePicture || {}} size={24} />
                    <Typography variant="body2">{currentClient ? currentClient.name : "No client selected"}</Typography>
                </Box>
                {currentClient && (
                    <React.Fragment>
                        <Box display="flex" gap="8px" alignItems={"center"} p="8px">
                            <Box>
                                <ProfilePicture
                                    data={currentClient?.profilePicture || {}}
                                    saveFile={saveProfilePicture}
                                    edit={true}
                                    size={150}
                                />
                            </Box>
                            <Box display="flex" flexDirection={"column"} gap="8px" flex={1}>
                                <EditableFieldComponent currentClient={currentClient} field={"name"} label="Name" />
                                <EditableFieldComponent
                                    currentClient={currentClient}
                                    field={"fullName"}
                                    label="Full Name"
                                />
                                <EditableFieldComponent currentClient={currentClient} field={"email"} label="Email" />
                                <EditableFieldComponent currentClient={currentClient} field={"phone"} label="Phone" />
                            </Box>
                        </Box>
                        <Divider />
                        <Box display="flex" p="8px" pt="16px">
                            <Button size="small" variant="contained" onClick={handleAddItem}>
                                New Item
                            </Button>
                        </Box>
                        <Box flex={1} sx={{ overflowY: "auto" }} height={"100%"} flexDirection={"column"}>
                            <ItemsComponent client={currentClient} />
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        </Box>
    );
}
