import { Typography } from "@mui/material";

export default function formatWhatsAppStyle(text) {
    // Split the text into parts by "*" (bold) and "_" (italic)
    const parts = text.split(/(\*.*?\*|_.*?_)/).filter(Boolean);

    return parts.map((part, index) => {
        if (part.startsWith("*") && part.endsWith("*")) {
            // Bold text
            return (
                <Typography key={index} component="span" sx={{ fontWeight: "bold" }}>
                    {part.slice(1, -1)}
                </Typography>
            );
        } else if (part.startsWith("_") && part.endsWith("_")) {
            // Italic text
            return (
                <Typography key={index} component="span" sx={{ fontStyle: "italic" }}>
                    {part.slice(1, -1)}
                </Typography>
            );
        } else {
            // Regular text
            return part;
        }
    });
}
