import axios from "axios";
import OpenAI from "openai";
//created by wayne as testing environment
export default async function chatGptFunctionCalling(prompt, setMessages, messages, model) {
    const openai = new OpenAI();

    let msgs = [
        { role: "system", content: "You are a helpful assistant who answer questions from the user" },
        { role: "user", content: prompt }
    ];

    const chatHistory = messages.map((msg) => ({ ...msg }));
    msgs.splice(1, 0, ...chatHistory);
    msgs.pop();
    console.log(msgs);

    // const functions = [
    //     {
    //         name: "get_projects",
    //         description: "Get the price of a project",
    //         parameters: {
    //             type: "object",
    //             properties: {
    //                 project: {
    //                     type: "string",
    //                     description: "The project name"
    //                 }
    //             },
    //             required: ["project"]
    //         }
    //     },
    //     {
    //         name: "send_message",
    //         description: "Send whatsapp message to a number",
    //         parameters: {
    //             type: "object",
    //             properties: {
    //                 to: {
    //                     type: "string",
    //                     description: "Whatsapp number. Has to be a phone number"
    //                 },
    //                 message: {
    //                     type: "string",
    //                     description: "The message to be sent"
    //                 },
    //                 name: {
    //                     type: "string",
    //                     description: "Name of the message recipient"
    //                 }
    //             },
    //             required: ["to", "message", "name"]
    //         }
    //     }
    // ];

    // try {
    //     const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_KEY;

    //     const headers = {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${OPENAI_API_KEY}`
    //     };

    //     let data = {
    //         model: model,
    //         messages: msgs,
    //         functions: functions,
    //         function_call: "auto"
    //     };

    //     try {
    //         const response = await axios.post("https://api.openai.com/v1/chat/completions", data, { headers: headers });
    //         console.log(response.data);

    //         if (response["data"].choices[0].message.function_call) {
    //             let functionName = response["data"].choices[0].message.function_call.name;
    //             let functionArgs = response["data"].choices[0].message.function_call.arguments;
    //             console.log(functionArgs);

    //             let resolveFunctionResult, rejectFunctionResult;
    //             let functionResult = new Promise((resolve, reject) => {
    //                 resolveFunctionResult = resolve;
    //                 rejectFunctionResult = reject;
    //             });

    //             if (functionName === "get_projects") {
    //                 getProjects()
    //                     .then((result) => {
    //                         resolveFunctionResult(JSON.stringify(result));
    //                     })
    //                     .catch((error) => {
    //                         rejectFunctionResult(error);
    //                     });
    //             }

    //             if (functionName === "send_message") {
    //                 const values = JSON.parse(functionArgs);
    //                 sendMessage(values.message, values.to)
    //                     .then((result) => {
    //                         resolveFunctionResult(JSON.stringify(result));
    //                     })
    //                     .catch((error) => {
    //                         rejectFunctionResult(error);
    //                     });
    //             }

    //             console.log(await functionResult);

    //             let newMessages = [
    //                 ...messages,
    //                 {
    //                     role: "assistant",
    //                     content: null,
    //                     function_call: {
    //                         name: functionName,
    //                         arguments: functionArgs
    //                     }
    //                 },
    //                 {
    //                     role: "function",
    //                     name: functionName,
    //                     content: await functionResult
    //                 }
    //             ];

    //             data = { ...data, messages: newMessages };
    //             const response2 = await axios.post("https://api.openai.com/v1/chat/completions", data, {
    //                 headers: headers
    //             });
    //             console.log(response2["data"]);
    //             newMessages = [
    //                 ...newMessages,
    //                 { role: "assistant", content: response2["data"].choices[0].message.content }
    //             ];

    //             setMessages(newMessages);
    //         } else {
    //             const message = response["data"].choices[0].message.content;
    //             let newMessages = [
    //                 ...messages,
    //                 {
    //                     role: "assistant",
    //                     content: message
    //                 }
    //             ];
    //             setMessages(newMessages);
    //             return newMessages;
    //         }
    //     } catch (err) {
    //         console.log(err);
    //         return null;
    //     }
    // } catch (error) {
    //     console.error(error);
    //     return null;
    // }
    function getCurrentWeather(location, unit = "fahrenheit") {
        if (location.toLowerCase().includes("tokyo")) {
            return JSON.stringify({ location: "Tokyo", temperature: "10", unit: "celsius" });
        } else if (location.toLowerCase().includes("san francisco")) {
            return JSON.stringify({ location: "San Francisco", temperature: "72", unit: "fahrenheit" });
        } else if (location.toLowerCase().includes("paris")) {
            return JSON.stringify({ location: "Paris", temperature: "22", unit: "fahrenheit" });
        } else {
            return JSON.stringify({ location, temperature: "unknown" });
        }
    }


    async function runConversation() {
        // Step 1: send the conversation and available functions to the model
        const messages = [
            { role: "user", content: "What's the weather like in San Francisco, Tokyo, and Paris?" },
        ];
        const tools = [
            {
                type: "function",
                function: {
                    name: "get_current_weather",
                    description: "Get the current weather in a given location",
                    parameters: {
                        type: "object",
                        properties: {
                            location: {
                                type: "string",
                                description: "The city and state, e.g. San Francisco, CA",
                            },
                            unit: { type: "string", enum: ["celsius", "fahrenheit"] },
                        },
                        required: ["location"],
                    },
                },
            },
        ];


        const response = await openai.chat.completions.create({
            model: model,
            messages: messages,
            tools: tools,
            tool_choice: "auto", // auto is default, but we'll be explicit
        });
        const responseMessage = response.choices[0].message;

        // Step 2: check if the model wanted to call a function
        const toolCalls = responseMessage.tool_calls;
        if (responseMessage.tool_calls) {
            // Step 3: call the function
            // Note: the JSON response may not always be valid; be sure to handle errors
            const availableFunctions = {
                get_current_weather: getCurrentWeather,
            }; // only one function in this example, but you can have multiple
            messages.push(responseMessage); // extend conversation with assistant's reply
            for (const toolCall of toolCalls) {
                const functionName = toolCall.function.name;
                const functionToCall = availableFunctions[functionName];
                const functionArgs = JSON.parse(toolCall.function.arguments);
                const functionResponse = functionToCall(
                    functionArgs.location,
                    functionArgs.unit
                );
                messages.push({
                    tool_call_id: toolCall.id,
                    role: "tool",
                    name: functionName,
                    content: functionResponse,
                }); // extend conversation with function response
            }
            const secondResponse = await openai.chat.completions.create({
                model: model,
                messages: messages,
            }); // get a new response from the model where it can see the function response
            return secondResponse.choices;
        }
    }

    try {
        const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_KEY;

        runConversation();
    } catch (err) {
        console.log('err:', err)
    }
}

// const getProjects = async () => {
//     return Promise.resolve([
//         { project: "Hugoz", price: "RM1700psf" },
//         { project: "PUHCONG", price: "RM1500psf" },
//     ]);
// };

// const sendMessage = async (message, to, from = "keatweichonggmailcom") => {
//     console.log(message, to, from);
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//         number: to,
//         message: message,
//         from: from
//     });

//     var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow"
//     };

//     try {
//         const response = await fetch("https://api.djc.ai/send-message", requestOptions);
//         const result = await response.json();
//         console.log(result);
//         return Promise.resolve({ result: result.status ? "Message sent" : result.message });
//     } catch (err) {
//         console.log(err);
//         return Promise.resolve({ result: "Failed sending message" });
//     }
// };
