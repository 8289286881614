import { Box, Container } from "@mui/material";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
import SearchComponent from "../../components/SearchComponent";
import { AuthContext } from "../../context/AuthContext";
import { UsersContext } from "../../context/UsersContext";
import { db } from "../../firebase/firebase-utils";
import convertToLowerCase from "../../functions/common-functions/convertToLowerCase";
import formatNumber from "../../functions/common-functions/formatNumber";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";
import loadUsersRT from "../../functions/loadUsersRT";
import { GridContainer, GridDivider, GridFlexBox, Loading, Title } from "../../themes/themes";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import PaginationComponent from "./../../components/PaginationComponent";
import { Name } from "./../../themes/themes";
import convertDate from "./../../utils-functions/convertDate";
import MemberDetails from "./MemberDetails";

export default function UsersPage() {
    const { users, setUsers } = useContext(UsersContext);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AuthContext);
    const [page, setPage] = useState(1);

    const navigate = useNavigate();

    const fieldToDisplay = ["date", "displayName", "email", "phone", "addOn"];

    useEffect(() => {
        if (!user) return;
        if (user.role === "Super Admin") {
            const unsubscribe = loadUsersRT(user, setUsers, setLoading);
            return unsubscribe;
        } else {
            const collectionRef = collection(db, "users");
            const q = query(collectionRef, where("referral", "==", user.id));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const users = mapSnapshot(querySnapshot);
                setUsers(users);
                return unsubscribe;
            });
        }
    }, [user]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const handleSearch = (value) => {
        setPage(1);
        setSearch(value);
    };

    const checkUser = (user) => {
        console.log(user);
        navigate("/user-card-page/" + user.id);
    };

    let filteredUsers = users?.map((user) => ({ ...user, date: convertDate(user.date) || convertDate("2020-01-01") }));
    let totalPage = 1;
    if (!arrayIsEmpty(filteredUsers)) {
        filteredUsers = filteredUsers.filter((user) => {
            const searchField =
                convertToLowerCase(user.displayName) +
                convertToLowerCase(user.email) +
                convertToLowerCase(user.addOn || "");
            return searchField.includes(convertToLowerCase(search));
        });
        totalPage = Math.ceil(filteredUsers.length / 50);
        filteredUsers.sort((a, b) => b.date - a.date);
    }

    return (
        <Box m={1}>
            <Container maxWidth="sm">
                <GridContainer>
                    <GridFlexBox gap="8px">
                        <Title>Users Page</Title>
                        <ExportToExcel fieldToDisplay={fieldToDisplay} data={filteredUsers} />
                    </GridFlexBox>
                    <GridFlexBox gap="8px">
                        <PaginationComponent page={page} totalPage={totalPage} setPage={setPage} />
                        <Name>
                            Total records: &nbsp;
                            {filteredUsers && formatNumber(filteredUsers.length, 0)}
                        </Name>
                    </GridFlexBox>
                    <GridFlexBox>
                        <SearchComponent handleSearch={handleSearch} search={search} />
                    </GridFlexBox>
                    <GridDivider />
                    <Loading loading={loading} />
                    {filteredUsers &&
                        filteredUsers
                            .slice((page - 1) * 50, page * 50)
                            .map((user, i) => <MemberDetails key={i} checkUser={checkUser} user={user} />)}
                    <GridFlexBox>
                        <PaginationComponent page={page} totalPage={totalPage} setPage={setPage} />
                        <Name>
                            Total records: &nbsp;
                            {filteredUsers && formatNumber(filteredUsers.length, 0)}
                        </Name>
                    </GridFlexBox>
                </GridContainer>
            </Container>
        </Box>
    );
}
