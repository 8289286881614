import {
    Button,
    IconButton,
    Typography,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from "@mui/material";
import { GridContainer, GridDivider, GridFlexBox, Loading, Title } from "../themes/themes";
import axios from "axios";
import UploadFilesButton from "./UploadFile/UploadFilesButton";
import { useState } from "react";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import DeleteIcon from "@mui/icons-material/Delete";
import useNotification from "../context-utils/NotificationContext";
import { grey } from "@mui/material/colors";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

const PDFFileUploader = ({ user, setOpen }) => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        keyword: "Customer",
        skipCount: 2
    });
    const [errors, setErrors] = useState({});
    const [preview, setPreview] = useState(null);
    const [advanced, setAdvanced] = useState(false);

    const notify = useNotification();

    const isPDF = (file) => {
        return file.name.toLowerCase().endsWith(".pdf");
    };

    const validateValues = () => {
        let error = {};

        if (!values.keyword) {
            error = {
                ...error,
                keyword: "Keyword is required."
            };
        }

        if (!values.skipCount) {
            error = {
                ...error,
                skipCount: "Skip count is required."
            };
        }

        return error;
    };

    const handleValuesChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleClick = async () => {
        if (!arrayIsEmpty(files)) {
            if (!isPDF(files[0])) {
                notify("Please select a PDF file", "error");
                return;
            }

            const err = validateValues();
            if (Object.keys(err).length > 0) {
                setErrors(err);
                return;
            } else {
                setErrors({});
            }

            try {
                const downloadableUrl = files[0].downloadURL;
                const keyword = values.keyword;
                const skipC = +values.skipCount;

                console.log(`downloadableUrl: ${downloadableUrl}, keyword: ${keyword}, skipC: ${skipC}`);

                setLoading(true);
                const response = await axios.post(
                    "https://asia-east2-facebook-api-59e5c.cloudfunctions.net/getCustomerPages",
                    {
                        downloadableUrl,
                        keyword,
                        skipC
                    }
                );

                if (response.status === 200) {
                    console.log("Success:", response.data);
                    setPreview(response.data);
                    setLoading(false);
                    return response.data;
                } else {
                    console.error("Error:", response);
                    setLoading(false);
                    return null;
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
                setLoading(false);
                return null;
            }
        } else {
            notify("Please select a PDF file", "error");
            return;
        }
    };

    const handleFile = async (files) => {
        console.log(files);
        setFiles(files);
    };

    const handleToggleDetails = () => {
        setAdvanced(!advanced);
    };

    const handleProcessClick = async () => {
        const sqlTaskColRef = collection(db, "users", user.id, "sqlTasks");
        setLoading(true);

        try {
            // First addDoc into the user's subcollection called sqlTasks
            const docRef = await addDoc(sqlTaskColRef, {
                createdBy: user.id,
                motherFileName: files[0].name,
                motherFileInfo: files[0],
                createdDate: new Date(),
                status: "running"
            });

            const response = await axios.post(
                "https://asia-east2-facebook-api-59e5c.cloudfunctions.net/generateCustomerPDFs",
                {
                    customerPages: preview,
                    downloadableUrl: files[0].downloadURL,
                    outputPath: `files/${user.id}/sqlTasks`,
                    userId: user.id,
                    docId: docRef.id
                }
            );

            if (response.status === 200) {
                console.log("PDFs generated successfully:", response.data);
            } else {
                console.error("Error:", response);
            }
            setLoading(false);
            setOpen(false);
        } catch (error) {
            console.error("An error occurred while generating PDFs:", error);
            setLoading(false);
        }
    };

    return (
        <GridContainer>
            <GridFlexBox m1 justifyContent="center">
                <UploadFilesButton handleFile={handleFile} />
            </GridFlexBox>
            {!arrayIsEmpty(files) && (
                <GridFlexBox m1 justifyContent="center">
                    <Typography sx={{ whiteSpace: "break-spaces" }} variant="subtitle1">
                        {files[0].name}
                    </Typography>
                    <IconButton aria-label="delete" size="small" onClick={() => setFiles([])}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </GridFlexBox>
            )}
            <GridFlexBox m1 justifyContent="center">
                <TextField
                    error={!!errors.keyword || false}
                    helperText={errors.keyword || ""}
                    required
                    fullWidth
                    id="keyword"
                    label="Keyword"
                    type="text"
                    InputLabelProps={{
                        shrink: true
                    }}
                    name="keyword"
                    variant="outlined"
                    value={values.keyword}
                    onChange={handleValuesChange}
                />
            </GridFlexBox>
            {advanced && (
                <GridFlexBox m1 justifyContent="center">
                    <TextField
                        error={!!errors.skipCount || false}
                        helperText={errors.skipCount || ""}
                        fullWidth
                        id="skip-count"
                        label="Skip Count"
                        type="number"
                        InputLabelProps={{
                            shrink: true
                        }}
                        name="skipCount"
                        variant="outlined"
                        value={values.skipCount}
                        onChange={handleValuesChange}
                    />
                </GridFlexBox>
            )}
            <Button type="button" fullWidth variant="text" color="primary" sx={{ m: 1 }} onClick={handleToggleDetails}>
                {advanced ? "Back" : "Advanced"}
            </Button>
            <Button variant="contained" component="span" onClick={handleClick} disabled={loading} color={preview ? "error" : "primary"}>
                {preview ? "Redo" : "Confirm"}
            </Button>
            {preview &&
                <Button variant="contained" component="span" onClick={handleProcessClick} sx={{ margin: "1%" }} disabled={loading} color="success">
                    Process
                </Button>}
            <GridDivider />
            <Loading loading={loading} />
            <Title>Preview</Title>
            {preview && (
                <>
                    <GridFlexBox>
                        <TableContainer
                            component={Paper}
                            sx={{ height: "90%", width: "90%", backgroundColor: grey[50] }}
                        >
                            <Table sx={{ width: "100%" }} aria-label="preview table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Keyword</TableCell>
                                        <TableCell>Pages</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(preview).map((key, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{key}</TableCell>
                                            <TableCell>{preview[key].map((page) => page + 1).join(", ")}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </GridFlexBox>
                </>
            )}
        </GridContainer>
    );
};

export default PDFFileUploader;
