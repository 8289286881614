import { Container, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import MarkDown from "../../components/MarkDown";
import { GridContainer, GridDivider, GridFlexBox } from "../../themes/themes";

export default function ThankYouPage({ form, setThankyou, response }) {
    const [thankyouMessage, setThankyouMessage] = useState("");

    useEffect(() => {
        let message = form.thankyouMessage || `Dear ${response.name},  \nWe have received your submission. Thank you.`;

        // Replace all occurrences of "{name}" with response.name
        message = message.replace(/{name}/g, response.name);

        setThankyouMessage(message);
    }, [form, response]);

    return (
        <Container maxWidth="xs">
            <GridContainer>
                <Grid item>
                    <MarkDown markdown={thankyouMessage} />
                </Grid>
                <GridDivider />
                <GridFlexBox fs>
                    <Button onClick={() => setThankyou(false)}>Back</Button>
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
