import { Box, Button, Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import { addDoc, collection, doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect } from "react";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { GridContainer, GridDivider, GridFlexBox, Loading, Title } from "../../themes/themes";
import { Name } from "../../themes/themes";

export default function StripeCheckoutPageEliteone() {
    const { user } = useContext(AuthContext);
    const [docId, setDocId] = React.useState();
    const [url, setUrl] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(0);

    useEffect(() => {
        console.log(docId);
        if (docId) {
            console.log("docId: ", docId);
            const docRef = doc(db, "customers", user.uid, "checkout_sessions", docId);
            return onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data();
                if (error) {
                    // Show an error to your customer and
                    // inspect your Cloud Function logs in the Firebase console.
                    alert(`An error occured: ${error.message}`);
                    setLoading(false);
                }
                if (url) {
                    // We have a Stripe Checkout URL, let's redirect.
                    console.log("url: ", url);
                    setUrl(url);
                    setLoading(false);
                }
            });
        }
    }, [docId]);

    const loadStripe = async (priceId) => {
        const collectionRef = collection(db, "customers", user.uid, "checkout_sessions");
        setLoading(true);
        const docRef = await addDoc(collectionRef, {
            price: priceId,
            success_url: window.location.origin,
            cancel_url: window.location.origin
        });

        console.log(user.uid);

        if (docRef.id) {
            console.log(docRef.id);
            setDocId(docRef.id);
        } else {
            setLoading(false);
            toast.error("Error creating checkout session");
        }
    };

    return (
        <Container maxWidth="md">
            <GridContainer>
                <GridFlexBox>
                    <Title>Subscription</Title>
                </GridFlexBox>
                <GridDivider />
                <GridFlexBox gap="14px">
                    <Name fs14>Current Subscription:</Name>
                    <Name fs14>{user.addOn === "whatsappCloud" ? "Whatsapp Cloud" : "None"}</Name>
                </GridFlexBox>
                <GridDivider />
                <Grid item container display={"flex"}>
                    <Grid item display={"flex"} xs={12} md={6}>
                        <Box sx={{ width: "100%" }}>
                            <Typography sx={{ fontWeight: "bold" }}>Professional Plan - Eliteone Member</Typography>
                            <Typography sx={{ fontWeight: "bold" }}>RM99.00</Typography>
                            <Typography sx={{ marginTop: "8px" }}>✅ DJC Leads Management</Typography>
                            <Typography>✅ DJC Whatsapp Broadcast</Typography>
                            <Typography>✅ DJC Whatsapp Flows</Typography>
                            <Typography>✅ DJC Whatsapp Funnels</Typography>

                            <Button
                                variant="contained"
                                onClick={() => loadStripe("price_1NKx4tLWoLTwDp2IdkUVTrF9")}
                                disabled={(user.addOn === "whatsappCloud" || loading) && user.role !== "Super Admin"}
                                sx={{ marginTop: "8px", marginBottom: "8px" }}
                            >
                                Subscribe
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Loading loading={loading} />
                <GridDivider />
                <GridFlexBox>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <Typography whiteSpace={"break-spaces"} sx={{ wordBreak: "break-word" }}>
                            {url}
                        </Typography>
                    </a>
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
