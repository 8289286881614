import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function deleteWhatsappBlast(id, user) {
    try {
        const docRef = doc(db, "users", user.id, "whatsappBlasts", id);
        await deleteDoc(docRef);
        console.log("WhatsappBlast successfully deleted!");
    } catch (error) {
        console.log(error);
    }
}
