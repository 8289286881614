import { collection, getDocs, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";
import convertDate from "../utils-functions/convertDate";

export default function loadMessages(roomId, setMessages, setLoading) {
    try {
        setLoading(true);
        const messagesRef = collection(db, "messages", roomId, "messages");
        const q = query(messagesRef, orderBy("date"));
        return onSnapshot(q, (snapshot) => {
            const newMessages = snapshot.docs.map((doc) => ({ ...doc.data(), roomId, id: doc.id }));
            newMessages.sort((a, b) => convertDate(a.date) - convertDate(b.date));

            setMessages(newMessages);

            setLoading(false);
        });
    } catch (error) {
        setLoading(false);
        console.log(error);
    }
}
