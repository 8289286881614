import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider, Icon, ListItemIcon, ListItemText } from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

export default function MenuDropdown({ handleClose, menuDropDown, anchorEl }) {
    const { user } = useContext(AuthContext);

    const display = (item) => {
        if (item.hide) {
            if (item.hide === user?.role) return null;
        }
        if (!item.role) {
            return true;
        } else {
            if (user && user.role === "Super Admin") {
                return true;
            } else {
                if (user && item.role === user.role) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };

    return (
        <div>
            <Menu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {menuDropDown.map(
                    (item, i) =>
                        display(item) &&
                        (item.action === "Divider" ? (
                            <Divider key={i} />
                        ) : (
                            <MenuItem onClick={handleClose} data-index={item.action} key={i}>
                                <ListItemIcon>{displayIcon(item)}</ListItemIcon>
                                <ListItemText primary={item.action} sx={{ color: item.color || "inherit" }} />
                            </MenuItem>
                        ))
                )}
            </Menu>
        </div>
    );
}

const displayIcon = (item) => {
    if (!item.icon) return <Icon>face</Icon>;
    if (typeof item.icon === "string") {
        return (
            <Icon fontSize="small" sx={{ color: item.color || "inherit" }}>
                {item.icon}
            </Icon>
        );
    } else {
        const element = React.cloneElement(item.icon, { sx: { color: item.color || "inherit" } });
        return element;
    }
};
