import { Button, Grid, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { GridContainer } from "../themes/themes";

const SQLFileProcess = ({ sqlTaskList, names, setNames, setOpenProcessCard, handleClickFilterNames }) => {
    const handleActionClick = (taskId) => {
        console.log("taskId: ", taskId);
        const taskObject = sqlTaskList.find((task) => task.id === taskId);
        console.log("Found the corresponding object:", taskObject);

        if (taskObject && taskObject.status === "completed" && taskObject.separatedFilesDownloadURLs) {
            const newNames = names.map((item) => {
                // Start with the existing properties of each name object.
                const updatedItem = { ...item, status: "" };

                // Find the separated file with a matching customerName.
                const separatedFile = taskObject.separatedFilesDownloadURLs.find(
                    (file) => file.customerName === item.name
                );

                // If a matching separated file is found, add its downloadURL to the name object.
                if (separatedFile) {
                    updatedItem.file = separatedFile.downloadURL;
                }

                return updatedItem;
            });
            console.log("handleActionClick: newNames= ", newNames);
            setNames(newNames);
            handleClickFilterNames("file")
            setOpenProcessCard(false);
        } else {
            console.log("handleActionClick: No matching task object found or task is not complete.");
            setOpenProcessCard(false);
        }
    };

    const columns = [
        {
            field: "motherFileName",
            headerName: "PDF File",
            flex: 1
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1
        },
        {
            field: "createdDate",
            headerName: "Date",
            renderCell: (params) => (
                <Typography>{new Date(params.row.createdDate.seconds * 1000).toDateString()}</Typography>
            ),
            flex: 1
        },
        {
            field: "actions",
            headerName: "Actions",
            renderCell: (params) => (
                <Button
                    variant="contained"
                    onClick={() => {
                        handleActionClick(params.row.id);
                    }}
                    disabled={params.row.status !== "completed"}
                >
                    Map
                </Button>
            )
        }
    ];

    return (
        <GridContainer>
            <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "90%" }}>
                <DataGrid
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    rows={sqlTaskList}
                    columns={columns}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } }
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true
                        }
                    }}
                />
            </Grid>
        </GridContainer>
    );
};

export default SQLFileProcess;
