import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Container, MenuItem, Select, InputLabel, FormControl, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import UploadExcel from "../components/UploadExcel";
import { useConfirmation } from "../context-utils/ConfirmationContext";
import { useInput } from "../context-utils/InputContext";
import useList from "../context-utils/ListContext";
import useNotification from "../context-utils/NotificationContext";
import { AuthContext } from "../context/AuthContext";
import { StoreContext } from "../context/StoreContext";
import addFolder from "../functions/addFolder";
import { getUserPages } from "../functions/getUserPages";
import loadFoldersRT from "../functions/loadFolders";
import { saveManualLead } from "../functions/saveManualLead";
import { savePage } from "../functions/savePage";
import { GridContainer, GridDivider, GridFlexBox, SelectItem, Title } from "../themes/themes";
import { notification } from "../utils-functions/notification";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const REQUIRED_FIELDS = [
    { label: "Name", id: "name" },
    { label: "Phone", id: "phone" },
    { label: "Email", id: "email" },
    { label: "Remark", id: "remark" },
    { label: "Source", id: "source" },
    { label: "Campaign", id: "campaign" }
];

export default function UploadDataPage() {
    const [requiredFields, setRequiredFields] = useState(REQUIRED_FIELDS);
    const [pages, setPages] = useState();
    const [page, setPage] = useState();
    const [folder, setFolder] = useState("");
    const { folders, setFolders } = useContext(StoreContext);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const { user } = useContext(AuthContext);

    const confirmation = useConfirmation();
    const list = useList();
    const getInput = useInput();
    const notify = useNotification();

    useEffect(() => {
        const unsubscribe = getUserPages(user, setPages);
        return unsubscribe;
    }, []);

    useEffect(() => {
        const getData = async () => {
            loadFoldersRT(user, setFolders);
        };
        getData();
    }, [user]);

    // const handleUpload = async (data) => {
    //     if (!page) {
    //         notify("Please select a page", "error");
    //         return;
    //     }
    //     const leads = data;
    //     console.log(leads);
    //     const message = (
    //         <div>
    //             <div>Name: {leads[0].name}</div>
    //             <div>Phone: {leads[0].phone}</div>
    //             <div>Email: {leads[0].email}</div>
    //             <div>Remark: {leads[0].remark}</div>
    //             <div>Source: {leads[0].source}</div>
    //             <div>Campaign: {leads[0].campaign}</div>
    //             <br />
    //         </div>
    //     );
    //     const response = await confirmation("Confirm upload", message);
    //     if (response) {
    //         try {
    //             let folderName;
    //             if (folder) {
    //                 folderName = `${user.id}:${folder.id}`;
    //             } else {
    //                 const currentDate = new Date();
    //                 const dateString = currentDate.toString();
    //                 const folderId = await addFolder(`uploaded-${dateString}`, user);
    //                 folderName = `${user.id}:${folderId}`;
    //             }
    //             await Promise.all(
    //                 leads.map(async (lead) => {
    //                     const { name, phone, email, remark, campaign, source } = lead;

    //                     await saveManualLead({
    //                         date: new Date(),
    //                         name,
    //                         phone,
    //                         email,
    //                         remark,
    //                         page,
    //                         user,
    //                         campaign,
    //                         source,
    //                         folders: [folderName]
    //                     });
    //                 })
    //             );
    //             console.log("uploaded");
    //             notify("Data uploaded", "success");
    //             return Promise.resolve(true);
    //         } catch (error) {
    //             notification("Opps", error.message, "danger");
    //         }
    //     }
    // };
    const handleUpload = async (data) => {
        if (!page) {
            notify("Please select a page", "error");
            return;
        }
        setOpen(true);
        const leads = data;
        console.log(leads);
        const message = (
            <div>
                <div>Name: {leads[0].name}</div>
                <div>Phone: {leads[0].phone}</div>
                <div>Email: {leads[0].email}</div>
                <div>Remark: {leads[0].remark}</div>
                <div>Source: {leads[0].source}</div>
                <div>Campaign: {leads[0].campaign}</div>
                <br />
            </div>
        );
        const response = await confirmation("Confirm upload", message);
        if (response) {
            try {
                let folderName;
                if (folder) {
                    folderName = `${user.id}:${folder.id}`;
                } else {
                    const currentDate = new Date();
                    const dateString = currentDate.toString();
                    const folderId = await addFolder(`uploaded-${dateString}`, user);
                    folderName = `${user.id}:${folderId}`;
                }

                // Process each lead sequentially
                for (const lead of leads) {
                    const { name, phone, email, remark, campaign, source } = lead;
                    // Wait for each lead to be processed before continuing
                    await saveManualLead({
                        date: new Date(),
                        name,
                        phone,
                        email,
                        remark,
                        page,
                        user,
                        campaign,
                        source,
                        folders: [folderName]
                    });
                }
                console.log("uploaded");
                notify("Data uploaded", "success");
            } catch (error) {
                notification("Opps", error.message, "danger");
            } finally {
                setOpen(false);
            }
        }
    };

    const handleClickPage = async () => {
        console.log(pages);
        const selectedPage = await list(pages, "Select page", "name", "facebook", true, true, "");
        if (selectedPage === "addAccount") {
            const newPageName = await getInput(
                "Create new page",
                "Please enter a name for your new page",
                "page name",
                ""
            );
            const myPage = {
                date: new Date(),
                email: user.email,
                name: newPageName,
                pageName: newPageName,
                userName: user.displayName,
                admins: [user.email]
            };
            console.log(myPage);
            const response = await savePage(myPage, user);
            console.log(response);
            notify("New page created for you", "success");
            setPage({ ...myPage, id: response.id });
        } else {
            if (selectedPage) {
                console.log(selectedPage);
                setPage(selectedPage);
            }
        }
    };

    const handleAddFolder = async () => {
        const response = await getInput("Add Folder", "Please enter a name for your new folder", "folder name", "");
        if (response) {
            const folderId = await addFolder(response, user);
            const folder = { id: folderId, folderName: response };
            setFolders((prev) => {
                if (prev) {
                    return [...prev, folder];
                } else {
                    return [folder];
                }
            });
            notify("Folder added", "success");
        }
    };

    return (
        <>
            <Container maxWidth="lg">
                <GridContainer>
                    <GridFlexBox>
                        <Title>Upload Data</Title>
                        <CloudUploadOutlinedIcon style={{ marginLeft: "16px" }} />
                    </GridFlexBox>
                    <GridDivider />
                    <GridFlexBox xs={9}>
                        <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
                            <InputLabel>Folder</InputLabel>
                            <Select fullWidth label="Folder" value={folder} onChange={(e) => setFolder(e.target.value)}>
                                {folders?.map((folder) => (
                                    <MenuItem key={folder.id} value={folder}>
                                        {folder.folderName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </GridFlexBox>
                    <GridFlexBox xs={3}>
                        <Button variant="contained" onClick={handleAddFolder}>
                            Add Folder
                        </Button>
                    </GridFlexBox>
                    <SelectItem value={page?.name} label="Page" onClick={handleClickPage} />
                    <UploadExcel
                        handleUpload={handleUpload}
                        requiredFields={requiredFields}
                        setRequiredFields={setRequiredFields}
                    />
                </GridContainer>
            </Container>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                Loading excel files. Please wait... 
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}
