import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function deleteAction(lead, action) {
    try {
        const actionRef = doc(db, "leads", lead.id, "actions", action.id);
        await deleteDoc(actionRef);
        console.log("Action Deleted");
    } catch (error) {
        console.log(error);
    }
}
