import { arrayUnion, doc, getDoc, updateDoc } from "@firebase/firestore";
import { setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export async function savePages(user, fbUser, pages) {
    try {
        pages.map(async (page) => {
            const docRef = doc(db, "pages", page.id);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                await updateDoc(docRef, {
                    accessToken: page.access_token,
                    admins: arrayUnion(user.email),
                    fbPage: true
                });
                console.log(page.name + " updated in Firestore");
            } else {
                await setDoc(
                    docRef,
                    {
                        date: new Date(),
                        name: page.name,
                        id: page.id,
                        userId: fbUser.id,
                        userName: fbUser.name,
                        accessToken: page.access_token,
                        email: user.email,
                        admins: [user.email]
                    },
                    { merge: true }
                );
                console.log(page.name + " added to Firestore");
            }
        });
    } catch (err) {
        console.log(err);
    }
}
