import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function addFlow(flow, user, clientId) {
    try {
        const collectionRef = collection(db, "users", user.id, "whatsappFlows");
        const data = { date: new Date(), name: flow, userId: user.id, clientId, status: "active" };
        const response = await addDoc(collectionRef, data);
        console.log("Document written with ID: ", response.id);
        return response.id;
    } catch (error) {
        console.log(error);
    }
}
