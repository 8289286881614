import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function updateImages(images, user, currentFlow) {
    try {
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentFlow.id);
        const newImages = images.map((image) => {
            let defaultTag = "files";
            if (image.type.includes("image")) defaultTag = "photo";
            if (image.type.includes("pdf")) defaultTag = "pdf";

            return {
                ...image,
                downloadURL: image.downloadURL,
                thumbnailURL: image.thumbnailURL || "",
                tag: image.tag || defaultTag,
                name: image.name,
                fullPath: image.fullPath || "",
                type: image.type || "",
                contentType: image.contentType || "",
                folderRef: image.folderRef || ""
            };
        });
        await setDoc(docRef, { images: newImages }, { merge: true });

        console.log("Images added");
    } catch (error) {
        console.log(error);
    }
}
