import { doc, setDoc} from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function saveCustomizeLeadHeader(
    user,newHeader
) {
    //create by wayne 2 nov 2023
    //receive current user and newHeader to save into users.setting.customizeLeadHeader
    
    try {       
        const userDocRef = doc(db, "users", user.email); 
        if (userDocRef) {           
            await setDoc(userDocRef, { settings: { customizeLeadHeader: newHeader } }, { merge: true });  

        } else {
            console.log("User " + user + " document not found.");
            return null;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}
