import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";

export default function loadUsersRT(user, setUsers, setLoading) {
    try {
        setLoading(true);
        const collectionRef = collection(db, "users");
        const q = query(collectionRef, orderBy("displayName", "asc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            console.log("Updating users...");
            const users = mapSnapshot(snapshot);
            console.log(users);
            setUsers(users);
            setLoading(false);
        });
        return unsubscribe;
    } catch (error) {
        console.log(error);
    }
}
