import { Button, Grid, TextField } from "@mui/material";
import { Container } from "@mui/system";
import { addDoc, collection, doc, getDoc, getDocs, increment, query, updateDoc, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MarkDown from "../../components/MarkDown";
import { db } from "../../firebase/firebase-utils";
import { GridContainer, GridDivider, GridFlexBox, Loading } from "../../themes/themes";
import { notification } from "../../utils-functions/notification";
import ThankYouPage from "./ThankYouPage";

export default function FormPage() {
    const { referralCode, formId } = useParams();
    const [form, setForm] = useState();
    const [referral, setReferral] = useState("");
    const [loading, setLoading] = useState(true);
    const [thankyou, setThankyou] = useState(false);
    const [formResponse, setFormResponse] = useState();

    useEffect(() => {
        const getData = async () => {
            console.log(formId);

            //Get form
            const formRef = doc(db, "forms", formId);
            setLoading(true);
            const formSnapshot = await getDoc(formRef);
            setLoading(false);
            if (!formSnapshot.exists()) {
                console.log("Form does not exist.");
                return;
            } else {
                console.log("Form: ", formSnapshot.data());
                setForm({ ...formSnapshot.data(), id: formSnapshot.id });
            }

            console.log(referralCode);
            const collectionRef = collection(db, "users");
            const q = query(collectionRef, where("referralCode", "==", referralCode));
            const snapshot = await getDocs(q);
            if (snapshot.empty) {
                console.log("No matching referral.");
                return;
            } else {
                const referral = snapshot.docs[0].data();
                setReferral(referral);
                console.log("Referral: ", referral);
            }
        };

        getData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formElement = e.currentTarget;
        const data = new FormData(e.currentTarget);

        const fields = form?.fields.map((field) => ({ name: field.name, value: data.get(field.name) }));
        let obj = {};
        fields.forEach((field) => {
            obj[field.name] = field.value.trim();
        });

        obj["date"] = new Date();
        obj["referralId"] = referral.id || "";
        obj["referralName"] = referral.displayName || "";
        obj["formId"] = form.id;
        obj["referralCode"] = referralCode;
        console.log(obj);
        setFormResponse(obj);
        const collectionRef = collection(db, "forms", formId, "submissions");
        await addDoc(collectionRef, obj);

        const docRef = doc(db, "forms", formId);
        await updateDoc(docRef, { submissionsCount: increment(1) });

        console.log("Submitted.");
        notification("Submitted.", "Thank you", "success");
        // Reset the form
        formElement.reset();
        setThankyou(true);
    };

    return !thankyou ? (
        <Container maxWidth="xs">
            <GridContainer>
                <Grid item>
                    <MarkDown markdown={form?.description} />
                </Grid>
                <GridDivider />
                <Loading loading={loading} />
                {form && (
                    <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                        {form?.fields?.map((field) => (
                            <React.Fragment key={field.name}>
                                <GridFlexBox mtb1>
                                    <TextField
                                        fullWidth
                                        type={field.type || "text"}
                                        name={field.name}
                                        size="small"
                                        label={field.name}
                                        required={field.isRequired || false}
                                    />
                                </GridFlexBox>
                            </React.Fragment>
                        ))}
                        <GridFlexBox mtb1>
                            <Button variant="contained" color="primary" fullWidth type="submit">
                                Submit
                            </Button>
                        </GridFlexBox>
                    </form>
                )}
            </GridContainer>
        </Container>
    ) : (
        <ThankYouPage form={form} setThankyou={setThankyou} response={formResponse} />
    );
}
