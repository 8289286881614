import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";

export default function loadUserProps(user, lead, setUserProps) {
    try {
        const docRef = doc(db, "users", user.id, "leadsProps", lead.id);
        return onSnapshot(docRef, (docSnap) => {
            const userProps = mapDocSnapshot(docSnap);
            if (docSnap.exists()) {
                setUserProps((prev) => ({ ...prev, ...userProps }));
            }
        });
    } catch (error) {
        console.log(error);
    }
}
