import formatDate from "./../../utils-functions/formatDate";
import convertDate from "./../../utils-functions/convertDate";

export default function mapSnapshotWithDate(snapshot, date) {
    if (!snapshot.empty) {
        const data = snapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
                path: doc.ref.path
            };
        });
        data.forEach((item) => {
            item[date] = formatDate(convertDate(item[date]), true);
        });
        return data;
    } else {
        return [];
    }
}
