import { Icon, Typography } from "@mui/material";

export default function PaginationComponent({ page, setPage, totalPage}) {
    
    const handleBackward = () => {
        if (page === 1) return;
        setPage(page - 1);
    };

    const handleForward = () => {
        if (page === totalPage) return;
        setPage(page + 1);
    };
    return (
        <>
            <Icon
                color="primary"
                onClick={handleBackward}
                style={{ cursor: "pointer" }}
            >
                arrow_back
            </Icon>
            <Typography variant="body2">
                Page {`${page} / ${totalPage}`}
            </Typography>
            <Icon
                color="primary"
                onClick={handleForward}
                style={{ cursor: "pointer" }}
            >
                arrow_forward
            </Icon>
        </>
    );
}
