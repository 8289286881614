import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function addFolder(folderName, user) {
    try {
        const collectionRef = collection(db, "users", user.id, "folders");
        // find folder with same name
        const q = query(collectionRef, where("folderName", "==", folderName));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            const { id } = await addDoc(collectionRef, {
                folderName,
                createdBy: user.id,
                created: new Date(),
                updated: new Date()
            });
            console.log("Add folder");
            return id;
        } else {
            return querySnapshot.docs[0].id;
        }
    } catch (error) {
        console.log(error);
    }
}
