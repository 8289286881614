import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import formatPhoneNumber from "../../functions/common-functions/formatPhoneNumber";

export default async function addAssignedNumber(whatsappPhone, user, userInput, currentFlow) {
    try {
        console.log("user:", user);
        console.log("userInput:", userInput);
        console.log("currentFlow:", currentFlow);

        let userPhone = formatPhoneNumber(userInput.phone);
        let agentPhone = formatPhoneNumber(whatsappPhone);
        let newChatRoomId = agentPhone + "-" + userPhone;
        const data = {
            chatRoomId: newChatRoomId,
            clientId: user.clientId,
            date: new Date(),
            flowDesign:
                "Say22 hi good morning and then introduce yourself when message receivedQ: What is your name?Answer: Real estate agent.If understand say yes.",
            from: formatData(userPhone),
            name: userInput.name,
            status: "active",
            to: formatData(agentPhone),
            userId: user.email,
            whatsappFlowId: currentFlow.id
        };
        const docRef = doc(db, "whatsappFlowAssignedNumbers", newChatRoomId);
        // Set the data for the document with the custom ID
        await setDoc(docRef, data, { merge: true });
    } catch (error) {
        console.log(error);
    }
}

function formatData(data) {
    // Remove non-numeric characters
    var numericData = data.replace(/\D/g, "");

    // Append '@c.us' to the numeric data
    var formattedData = numericData + "@c.us";

    return formattedData;
}
