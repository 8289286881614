import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Container, Paper } from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import {
    collection,
    doc,
    increment,
    onSnapshot,
    orderBy,
    query,
    runTransaction,
    updateDoc,
    where
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapSnapshotWithDate from "../../functions/common-functions/mapSnapshotWithDate";
import { GridContainer, GridFlexBox, Loading, Name } from "../../themes/themes";
import useWindowSize from "./../../hooks/useWindowSize";
import { arrayIsEmpty } from "./../../utils-functions/arrayIsEmpty";

export default function FormSubmissionsPage({ form }) {
    const [submissions, setSubmissions] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    const { user } = useContext(AuthContext);

    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 768;

    useEffect(() => {
        if (!form) return;
        const collectionRef = collection(db, "forms", form.id, "submissions");

        let q;

        if (form.userId === user.id) {
            q = query(collectionRef, orderBy("date", "desc"));
        } else {
            q = query(collectionRef, orderBy("date", "desc"), where("referralId", "==", user.id));
        }
        setLoading(true);
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const submissions = mapSnapshotWithDate(snapshot, "date");
            setSubmissions(submissions);
        });
        setLoading(false);
        return unsubscribe;
    }, [form]);

    useEffect(() => {
        if (arrayIsEmpty(submissions)) return;

        let columns = [
            { field: "date", headerName: "Date", width: 150 },
            {
                field: "name",
                headerName: "Name",
                width: 150,
                renderCell: (params) => <Name>{params.value}</Name>
            },
            { field: "phone", headerName: "Phone", width: 150 },
            { field: "email", headerName: "Email", width: 200 }
        ];

        Object.keys(submissions[0]).forEach((key) => {
            if (
                key === "date" ||
                key === "name" ||
                key === "email" ||
                key === "phone" ||
                key === "id" ||
                key === "path" ||
                key === "referralId" ||
                key === "referralName" ||
                key === "formId"
            )
                return;
            columns.push({
                field: key,
                headerName: key,
                width: 150,
                flex: isMobile ? 0 : 1
            });
        });

        columns.push({
            field: "referralId",
            headerName: "Referral Id",
            width: 150
        });

        columns.push({
            field: "referralName",
            headerName: "Referral Name",
            width: 150
        });

        setColumns(columns);
    }, [submissions, isMobile]);

    const confirmation = useConfirmation();
    
    // Function to delete selected submissions
    const deleteSubmissions = async () => {
        const response = await confirmation("Delete submissions?", "Press OK to confirm");
        if (response) {
            setLoading(true);
            try {
                await runTransaction(db, async (transaction) => {
                    rowSelectionModel.forEach((submissionId) => {
                        const submissionRef = doc(db, "forms", form.id, "submissions", submissionId);
                        transaction.delete(submissionRef);
                    });
                });

                const docRef = doc(db, "forms", form.id);
                await updateDoc(docRef, { submissionsCount: increment(-rowSelectionModel.length) });

                toast.success("Submissions deleted successfully");
            } catch (error) {
                console.error("Error deleting submissions: ", error);
                toast.error("Error deleting submissions");
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log("rowSelectionModel: ", rowSelectionModel);
    }, [rowSelectionModel]);

    return (
        <Container disableGutters maxWidth="none">
            <GridContainer>
                <Loading loading={loading} />
                {arrayIsEmpty(submissions) ? (
                    <Name>No submissions yet.</Name>
                ) : (
                    <>
                        <GridFlexBox fs>
                            <Paper sx={{ height: "87vh", width: "100%", overflowY: "auto" }}>
                                <DataGrid
                                    rows={submissions}
                                    columns={columns}
                                    slots={{
                                        toolbar: () => (
                                            <CustomToolbar
                                                onDelete={deleteSubmissions}
                                                disabled={rowSelectionModel.length === 0}
                                            />
                                        )
                                    }}
                                    onRowSelectionModelChange={(newRowSelectionModel) => {
                                        setRowSelectionModel(newRowSelectionModel);
                                    }}
                                    rowSelectionModel={rowSelectionModel}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 100
                                            }
                                        }
                                    }}
                                    rowsPerPageOptions={[5, 10, 20, 100]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            </Paper>
                        </GridFlexBox>
                    </>
                )}
            </GridContainer>
        </Container>
    );
}

function CustomToolbar({ onDelete, disabled }) {
    return (
        <GridToolbarContainer>
            <GridToolbar />
            <Box display="flex" alignItems={"center"} pt="4px">
                <Button startIcon={<DeleteIcon />} color="primary" onClick={onDelete} disabled={disabled} size="small">
                    Delete
                </Button>
            </Box>
        </GridToolbarContainer>
    );
}
