import { doc, setDoc, writeBatch } from "firebase/firestore";
import removeDuplicates from "../utils-functions/removeDuplicates";
import { notification } from "../utils-functions/notification";
import { db } from "../firebase/firebase-utils";

export default async function addLeadsIntoFolder(folder, user, selectData) {
    try {
        const batch = writeBatch(db);
        const folderName = user.id + ":" + folder.id;
        selectData.forEach((lead) => {
            let folders = lead.folders || [];
            const index = folders.findIndex((f) => f.toLowerCase().includes(user.id.toLowerCase()));
            if (index === -1) {
                folders.push(folderName);
            } else {
                folders[index] = folderName;
            }
            folders = removeDuplicates(folders);

            const docRef = doc(db, "leads", lead.id);
            batch.update(docRef, { folders });
        });

        await batch.commit();
        console.log("Assign to folder");

        const folderRef = doc(db, "users", user.id, "folders", folder.id);
        selectData.length;
        await setDoc(folderRef, { updated: new Date() }, { merge: true });
        console.log("Updated date");

        notification("Added", "Put into folder " + folder.folderName, "success");
    } catch (error) {
        console.log(error);
    }
}
