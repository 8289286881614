import { Button, Dialog, DialogTitle, DialogContent, FormControl, Select, MenuItem, InputLabel, Grid, List, ListItem, ListItemText, IconButton, Typography, Paper } from "@mui/material";
import SortIcon from '@mui/icons-material/Sort';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import { GridDivider } from "../themes/themes";

const SortDialogBox = ({
    LEADS_HEADER,
    sortingOptions,
    setSortingOptions
}) => {
    const [sorting, setSorting] = useState({
        column: '',
        mode: '',
    });
    const [openSort, setOpenSort] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSorting(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFilterAdd = () => {
        setSortingOptions((prev) => {
            if (prev) return [...prev, { id: sorting.column, mode: sorting.mode }];
            return [{ id: sorting.column, mode: sorting.mode }];
        });
        handleClearSorting();
    }

    const handleClearSorting = () => {
        setSorting({
            column: '',
            mode: ''
        });
    }

    const handleDelete = (id) => {
        const updatedSortingOptions = sortingOptions.filter((option) => option.id !== id);
        setSortingOptions(updatedSortingOptions);
    };

    return (
        <>
            <Dialog open={openSort} onClose={() => { setOpenSort(false); handleClearSorting(); }}
                sx={{ "& .MuiDialog-paper": { width: '75%' } }}
            >
                <DialogTitle>Sorting Options</DialogTitle>
                <DialogContent sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    flexDirection: "column",
                }}>
                    <Grid container>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">Current sorting:</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ height: "90px", overflowY: "auto" }}>
                                {sortingOptions && <List>
                                    {sortingOptions.map((option) => (
                                        <ListItem key={option.id}>
                                            <ListItemText primary={option.id} secondary={`Mode: ${option.mode}`} />
                                            <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(option.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItem>
                                    ))}
                                </List>}
                            </Grid>
                        </Grid>
                        <GridDivider />
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ marginTop: "4%" }}>
                                <InputLabel id="sort-column-id">Column</InputLabel>
                                <Select
                                    labelId="sort-column-id"
                                    id="sortColumn"
                                    name="column"
                                    value={sorting.column}
                                    label="Column"
                                    onChange={handleChange}
                                >
                                    {LEADS_HEADER.map((header) => (
                                        <MenuItem key={header.id} value={header.id}>
                                            {header.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ margin: "4% 0" }}>
                                <InputLabel id="sort-mode-id">Sorting Mode</InputLabel>
                                <Select
                                    labelId="sort-mode-id"
                                    id="sortMode"
                                    name="mode"
                                    value={sorting.mode}
                                    label="Sorting Mode"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"ascd"}>
                                        ascending
                                    </MenuItem>
                                    <MenuItem value={"desc"}>
                                        descending
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>

                        </Grid>
                    </Grid>

                    <Button variant="contained" size="small" onClick={handleFilterAdd}>Add</Button>
                </DialogContent>
            </Dialog>
            <Button
                variant="text"
                startIcon={<SortIcon />}
                size="small"
                id="sort-button"
                color="warning"
                onClick={() => setOpenSort(true)}
                sx={{ paddingRight: "2%" }}
            >
                Sort by
            </Button>
        </>
    );
}

export default SortDialogBox;