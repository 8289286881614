import { collection, limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";

export default function loadMessages(user, currentChatRoom, setMessages, setLoading, extraMessage = 0) {
    try {
        const amountToFetch = 50 + currentChatRoom.unread + extraMessage;
        console.log("amountToFetch: ", amountToFetch);
        const collectionRef = collection(db, "whatsappMessages", currentChatRoom.id, "messages");
        const q = query(collectionRef, orderBy("date", "desc"), limit(amountToFetch));
        setLoading(true);
        return onSnapshot(q, (snapshot) => {
            const data = mapSnapshot(snapshot);
            data.reverse();
            setMessages(data);
            setLoading(false);
            console.log("messages: ", data);
        });
    } catch (err) {
        console.log(err);
        setLoading(false);
    }
}
