import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function updateKeywords(user, currentFlow, newKeywords) {
    try {
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentFlow.id);
        await updateDoc(docRef, { keywords: newKeywords });
        console.log("Updated keywords: ", currentFlow.id);
    } catch (error) {
        console.log(error);
    }
}
