import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";

export default async function loadFriends(user) {
    try {
        const collectionRef = collection(db, "users", user.id, "friends");
        const q = query(collectionRef, orderBy("displayName"));
        const snapshot = await getDocs(q);
        const friends = mapSnapshot(snapshot);
        friends.sort((a, b) => a.displayName.localeCompare(b.displayName));
        return friends;
    } catch (error) {
        console.log(error);
    }
}
