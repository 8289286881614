// Import the required functions from the Firebase SDK
import { deleteObject, ref } from "firebase/storage";
import { storage } from "../../firebase/firebase-utils";

// Function to delete a file
function deleteFile(filePath) {
    // Create a reference to the file to delete
    const fileRef = ref(storage, filePath);

    // Delete the file
    deleteObject(fileRef)
        .then(() => {
            console.log("File deleted successfully");
        })
        .catch((error) => {
            console.error("Error removing file: ", error);
        });
}

export default deleteFile;
