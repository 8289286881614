import { IconButton, Grid, Paper, Tab, Tabs } from "@mui/material";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { StoreContext } from "../../context/StoreContext";
import { useState, useContext, useRef, useEffect } from "react";

import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from '@mui/icons-material/Refresh';

const LeadDashBoardTable = ({
    leadDashdata,
    callerDashdata,
    setLoading,
    source,
    setSource
}) => {
    const { setLeadDashdata, setCallerDashdata } = useContext(StoreContext);
    const [globalFilter, setGlobalFilter] = useState(null);
    const tableWrapperRef = useRef(null);

    useEffect(() => {
        if (tableWrapperRef.current) {
            const headers = tableWrapperRef.current.querySelectorAll('.p-datatable-thead > tr > th');
            const cells = tableWrapperRef.current.querySelectorAll('.p-datatable-tbody > tr > td');

            headers.forEach((header) => {
                header.style.whiteSpace = 'normal';
                header.style.overflowWrap = 'break-word';
                header.style.wordWrap = 'break-word';
            });

            cells.forEach((cell) => {
                cell.style.whiteSpace = 'normal';
                cell.style.overflowWrap = 'break-word';
                cell.style.wordWrap = 'break-word';
            });
        }
    }, []);

    //To refresh and fetch the latest lead data
    //Refresh according to the current source
    const handleRefresh = (s) => {
        if (s === 0) {
            setLeadDashdata(null);
        }
        else {
            setCallerDashdata(null);
        }
    }

    const handleChangeSource = (e, value) => {
        console.log("value: ", value);
        setSource(value);
    }

    const columns = [
        { field: 'name', header: source === 0 ? 'Caller' : 'Lead Owner' },
        { field: 'totalLeadAssignment', header: 'Total Leads' },
        { field: 'numberOfAppointments', header: 'Total Appt' },
        { field: "appointmentRate", header: 'Appt Rate (%)' },
        { field: 'numberOfBookedSales', header: 'Total B. Sale' },
        { field: "bookedSaleRate", header: 'B. Sale Rate (%)' },
        { field: 'numberOfUntouchedLeads', header: 'Pending Clearance' },
        { field: "avgTimeToTakeAction", header: 'Avg React Time (hours)' }
    ];

    const header = (
        <Grid container>
            <Grid item xs={10}>
                <span className="p-input-icon-left">
                    <SearchIcon />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
                </span>
                <Tabs
                    value={source}
                    onChange={handleChangeSource}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    sx={{width: "100%"}}
                >
                    <Tab label="Leads Owner" />
                    <Tab label="Caller" />
                </Tabs>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ width: "100%" }}>
                <IconButton aria-label="refresh" onClick={() => handleRefresh(source)}>
                    <RefreshIcon />
                </IconButton>
            </Grid>
        </Grid>
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper>
                    <div ref={tableWrapperRef}>
                        <DataTable
                            value={source === 0 ? leadDashdata : callerDashdata}
                            showGridlines
                            stripedRows
                            paginator
                            rows={50}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            sortMode="multiple"
                            globalFilter={globalFilter}
                            header={header}
                            tableStyle={{ whiteSpace: "normal" }}
                        >
                            {columns.map((col, i) => (
                                <Column
                                    key={i}
                                    field={col.field}
                                    header={col.header}
                                    sortable
                                />
                            ))}
                        </DataTable>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default LeadDashBoardTable;