import convertDate from "../../functions/common-functions/convertDate";
import formatDate from "../../functions/common-functions/formatDate";
import formatNumber from "../../functions/common-functions/formatNumber";

export function checkItem(item, type, user) {
    if (!item) return "-";
    switch (typeof item) {
        case "string":
            return item;
        case "object": {
            if (type === "date") {
                let date = convertDate(item);
                return formatDate(date);
            }
            if (type === "labels") {
                let labels = "";
                let labelArray = [];
                item.forEach((label) => {
                    if (label.userEmail === user.id) {
                        labelArray.push(label.name || label.label);
                    }
                });
                labelArray.sort((a, b) => a.localeCompare(b));
                labelArray.forEach((label) => {
                    labels += "[" + label + "] ";
                });
                labels = labels || "-";
                return labels;
            }
            if (type === "assignment") {
                let assignmentsArray = [];
                item.forEach((assignment) => {
                    assignmentsArray.push(assignment.assign.name);
                });
                assignmentsArray.sort((a, b) => a.localeCompare(b));
                const str = assignmentsArray.map(String).join(', ');
                return str;
            }

            //added by wayne 8-11-2023
            //addition field of column admins
            if (type === "admins") {
                let adminsArray = [];
                item.forEach((admin) => {
                    adminsArray.push(admin);
                });
                adminsArray.sort((a, b) => a.localeCompare(b));
                const str = adminsArray.map(String).join(', ');               
                return str;
            }
            return "NA";
        }
        case "number": { 
                
            //modified by wayne : 1 NOV 2023 
            // return formatNumber(formatteditemNumber);  
         
            return item;
        }
        default:
            return item;
    }
}
