import { Button, IconButton, InputAdornment, Dialog, DialogTitle, DialogContent, FormControl, Select, MenuItem, InputLabel, OutlinedInput } from "@mui/material";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import useList from "../context-utils/ListContext";
import createDropDownList from "../pages/LeadsTablePage/createDropDownList";
import { useState } from "react";

const FilterDialogBox = ({
    LEADS_HEADER,
    setSearch2,
    user,
    leads
}) => {
    const [filter, setFilter] = useState({
        column: '',
        value: '',
        mode: ''
    });
    const [openFilter, setOpenFilter] = useState(false);

    const list = useList();

    const handleClick = async (id, mode) => {
        console.log(id);
        // SEARCH OR SORT
        const dropDownList = createDropDownList(leads, id, LEADS_HEADER, user);
        const response = await list(dropDownList, "Search", "name", "face", false, true);
        if (response) {
            setSearch2((prev) => {
                if (prev) return [...prev, { id, value: response.name, mode: mode }];
                return [{ id, value: response.name, mode: mode }];
            });
            setOpenFilter(false);
            handleClearFilter();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilter(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFilterAdd = () => {
        setSearch2((prev) => {
            if (prev) return [...prev, { id: filter.column, value: filter.value, mode: filter.mode }];
            return [{ id: filter.column, value: filter.value, mode: filter.mode }];
        });
        setOpenFilter(false);
        handleClearFilter();
    }

    const handleClearFilter = () => {
        setFilter({
            column: '',
            value: '',
            mode: ''
        });
    }

    return (
        <>
            <Dialog open={openFilter} onClose={() => { setOpenFilter(false); handleClearFilter(); }}
                sx={{ "& .MuiDialog-paper": { width: '50%' } }}
            >
                <DialogTitle>Filter Options</DialogTitle>
                <DialogContent sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    flexDirection: "column",
                }}>
                    <FormControl fullWidth sx={{ marginTop: "4%" }}>
                        <InputLabel id="filter-column-id">Column</InputLabel>
                        <Select
                            labelId="filter-column-id"
                            id="filterColumn"
                            name="column"
                            value={filter.column}
                            label="Column"
                            onChange={handleChange}
                        >
                            {LEADS_HEADER.map((header) => (
                                <MenuItem key={header.id} value={header.id}>
                                    {header.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ margin: "4% 0" }}>
                        <InputLabel id="filter-mode-id">Filter Mode</InputLabel>
                        <Select
                            labelId="filter-mode-id"
                            id="filterMode"
                            name="mode"
                            value={filter.mode}
                            label="Filter Mode"
                            onChange={handleChange}
                        >
                            <MenuItem value={"contain"}>
                                contain
                            </MenuItem>
                            <MenuItem value={"not contain"}>
                                not contain
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: "4%" }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-value">Value</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-value"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="show-value-list"
                                        onClick={() => handleClick(filter.column, filter.mode)}
                                        edge="end"
                                    >
                                        <UnfoldMoreIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={filter.value}
                            name="value"
                            onChange={handleChange}
                            label="Value"
                            placeholder="Enter filter value or click the icon on the right"
                        />
                    </FormControl>
                    <Button variant="contained" size="small" onClick={handleFilterAdd}>Add</Button>
                </DialogContent>
            </Dialog>
            <Button
                variant="text"
                startIcon={<FilterListIcon />}
                size="small"
                id="filter-button"
                onClick={() => setOpenFilter(true)}
                sx={{paddingRight: "2%"}}
            >
                Filter
            </Button>
        </>
    );
}

export default FilterDialogBox;