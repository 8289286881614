export default function mapSnapshot(snapshot) {
    if (!snapshot.empty) {
        const data = snapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
                path: doc.ref.path
            };
        });
        return data;
    } else {
        return [];
    }
}
