import { checkItem } from "./checkItem";

const MAX_WIDTH = 400;
const MIN_WIDTH = 100;

export default function getMaxWidth(objects, item, user) {
    let maxWidth = parseFloat(item.width);
    objects.forEach((object) => {
        const value = checkItem(object[item.id], item.type, user);
        if (value) {
            const width = value.length * 9;
            if (width > maxWidth) {
                maxWidth = width;
            }
        }
    });
    if (maxWidth > MAX_WIDTH) maxWidth = MAX_WIDTH;
    if (maxWidth < MIN_WIDTH) maxWidth = MIN_WIDTH;
    return maxWidth;
}
