import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function addLabel(user, label) {
    try {
        const collectionRef = collection(db, "users", user.id, "labels");
        const newLabel = {
            ...label,
            created: new Date(),
            createdBy: { displayName: user.displayName, email: user.id }
        };
        const refId = await addDoc(collectionRef, newLabel);
        console.log(refId.id, "successfully added");
        return refId;
    } catch (error) {
        console.log(error);
    }
}
