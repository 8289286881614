import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Grid, Box } from "@mui/material";
import QRCode from "qrcode.react";

const WhatsappLinkGenerator = ({ whatsappPhone = "" }) => {
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [copied, setCopied] = useState(false);
    const [showQR, setShowQR] = useState(false);

    useEffect(() => {
        if (!phone || !message) {
            setShowQR(false);
        }
    }, [phone, message]);

    const generateLink = () => {
        return `https://api.whatsapp.com/send/?phone=${phone}&text=${encodeURIComponent(message)}`;
    };

    return (
        <Grid container display={"flex"}>
            {whatsappPhone && (
                <Grid item xs={12}>
                    <Button
                        size="small"
                        variant="outlined"
                        sx={{ marginBottom: "12px" }}
                        onClick={() => setPhone(whatsappPhone)}
                    >
                        {whatsappPhone}
                    </Button>
                </Grid>
            )}

            <Grid item xs={12}>
                <Box display={"flex"} justifyContent="space-between" gap="8px">
                    <TextField
                        label="Phone"
                        size="small"
                        variant="outlined"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label="Message"
                        variant="outlined"
                        size="small"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        fullWidth
                        multiline
                        rows={3}
                    />
                </Box>
                <Box sx={{ marginTop: "8px" }} display={"flex"}>
                    <CopyToClipboard text={generateLink()} onCopy={() => setCopied(true)}>
                        <Button variant="contained" color="primary" disabled={!phone || !message}>
                            Generate Whatsapp Link
                        </Button>
                    </CopyToClipboard>

                    <Box m={1}>{copied && <span style={{ color: "green" }}>Copied.</span>}</Box>
                </Box>
                <Box display="flex" sx={{ marginTop: "8px" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowQR(!showQR)}
                        disabled={!phone || !message}
                    >
                        {showQR ? "Hide" : "Generate"} QR Code
                    </Button>
                </Box>
                <Box sx={{ marginTop: "8px" }}>{showQR && <QRCode value={generateLink()} />}</Box>
            </Grid>
        </Grid>
    );
};

export default WhatsappLinkGenerator;
