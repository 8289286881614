export default function convertStringToCamelCase(sentence) {
    if (typeof sentence !== 'string') {
        sentence = String(sentence);
    }

    if (!sentence) {
        return "";
    }

    sentence = sentence.trim();

    let camelCase = sentence.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (camelCaseMatch, i) {
        if (+camelCaseMatch === 0) return "";
        return i === 0 ? camelCaseMatch.toLowerCase() : camelCaseMatch.toUpperCase();
    });

    camelCase = camelCase.replace(/[^\w\s]/gi, "");
    return camelCase;
}
