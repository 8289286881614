import React, { useState } from "react";
import { Container, Grid, TextareaAutosize, Button, TextField, FormControlLabel } from "@mui/material";
import { GridContainer, GridDivider, GridFlexBox, Name, Title } from "../../themes/themes";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Checkbox from "@mui/material/Checkbox";
import { db } from "../../firebase/firebase-utils";
import { get } from "firebase/database";
import mapDocSnapshot from "./../../utils-functions/mapDocSnapshot";

export default function UpdateAffiliates() {
    const [emails, setEmails] = useState([]);
    const [affiliateId, setAffiliateId] = useState("");
    const [override, setOverride] = useState(false);

    const handlePasteData = (data) => {
        console.log(data);
        data = data.filter((item) => item !== "");
        setEmails(data);
    };

    const handleEdit = async () => {
        for (let email of emails) {
            const docRef = doc(db, "users", email);
            const userSnap = await getDoc(docRef);
            const user = mapDocSnapshot(userSnap);
            if (override) {
                await updateDoc(docRef, { referral: affiliateId });
                console.log(email, " is updated");
            } else {
                if (!user.referral) {
                    await updateDoc(docRef, { referral: affiliateId });
                    console.log(email, " is updated");
                } else {
                    console.log(email, " already has a referral");
                }
            }
        }
    };

    const handleChange = (event) => {
        setOverride(event.target.checked);
    };

    return (
        <Container maxWidth="sm">
            <GridContainer>
                <GridFlexBox>
                    <Title>Update Affiliates</Title>
                </GridFlexBox>
                <GridDivider />
                {emails.map((email, i) => (
                    <React.Fragment key={email}>
                        <GridFlexBox xs={1}>
                            <Name>{i}</Name>
                        </GridFlexBox>
                        <GridFlexBox xs={11} fs>
                            <Name>{email}</Name>
                        </GridFlexBox>
                    </React.Fragment>
                ))}
                <GridFlexBox gap={"8px"}>
                    <TextField
                        value={affiliateId}
                        onChange={(e) => setAffiliateId(e.currentTarget.value)}
                        label={"Affiliate Id"}
                    />
                    <Button variant={"contained"} onClick={handleEdit}>
                        Batch Edit
                    </Button>
                    <FormControlLabel
                        label="Override existing affiliate"
                        control={<Checkbox checked={override} onChange={handleChange} />}
                    />
                </GridFlexBox>
                <GridFlexBox>
                    <ExcelLikeInput handlePasteData={handlePasteData} />
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}

const ExcelLikeInput = ({ handlePasteData }) => {
    const [textAreaData, setTextAreaData] = useState("");

    const handleDataProcessing = () => {
        let pasteData = textAreaData.split("\n");
        console.log(pasteData);
        handlePasteData(pasteData);
    };

    return (
        <Grid container display={"flex"} flexDirection={"column"}>
            <GridFlexBox>
                <TextareaAutosize
                    minRows={5}
                    placeholder="Paste Names and Phones. Copy from Excel or separate name and phone with ','"
                    value={textAreaData}
                    onChange={(e) => setTextAreaData(e.target.value)}
                />
            </GridFlexBox>

            <GridFlexBox m1>
                <Button variant="contained" color="primary" onClick={handleDataProcessing}>
                    Process Data
                </Button>
            </GridFlexBox>
        </Grid>
    );
};
