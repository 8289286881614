import { Box, Icon, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import getListingThumbnail from "./getListingThumbnail";
import isFileImage from "../../components/UploadFile/isFileImage";

export default function ListingImageComponent({ image,currentListingId, width = "150px", handleDelete, i }) {
    const [imageThumbnailURL, setImageThumbnailURL] = React.useState();
    
    useEffect(() => {
        const loadThumbnail = async () => {
            if (isFileImage(image)) {
                const thumbnailURL = await getListingThumbnail(image,currentListingId);
                setImageThumbnailURL(thumbnailURL);
            }
        };
        loadThumbnail();
    }, [image]);

    if (!isFileImage(image))
        return (
            <a href={image.downloadURL} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                <Icon sx={{ fontSize: "45px" }}>article</Icon>
            </a>
        );

    return (
        <Box width={width}>
            {handleDelete && (
                <IconButton sx={{ marginBottom: "-50px" }} onClick={() => handleDelete(i)}>
                    <Icon>delete</Icon>
                </IconButton>
            )}

            <img src={imageThumbnailURL || image.downloadURL} alt="file" width={"100%"} />
        </Box>
    );
}
