import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";

export default function loadBookedSales(user, setBookedSales) {
    try {
        const collectionRef = collection(db, "users", user.id, "bookedSales");
        const q = query(collectionRef, orderBy("date"));
        return onSnapshot(q, (bookedSalesSnap) => {
            let bookedSales = mapSnapshot(bookedSalesSnap);
            setBookedSales(bookedSales);
        });
    } catch (error) {
        console.log(error);
    }
}
