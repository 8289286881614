import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase-utils";

export default async function saveUserSettings(user, settings) {
    try {
        const docRef = doc(db, "users", user.id);
        await updateDoc(docRef, { settings });
        console.log("User settings saved successfully!");
    } catch (error) {
        console.log(error);
    }
}
