import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Container } from "@mui/material";
import { blue, yellow } from "@mui/material/colors";
import { useContext, useEffect, useMemo, useState } from "react";
import { LeadsFlexBox } from "../components/LeadsFlexBox";
import { AuthContext } from "../context/AuthContext";
import { StoreContext } from "../context/StoreContext";
import loadAppointments from "../functions/loadAppointments";
import loadFollowUps from "../functions/loadFollowUps";
import loadLead from "../functions/loadLead";
import { GridContainer, GridFlexBox, Name } from "../themes/themes";
import convertDate from "../utils-functions/convertDate";

export default function CalendarPage() {
    const { followUps, setFollowUps, addUnsubscribeStore } = useContext(StoreContext);
    const { appointments, setAppointments } = useContext(StoreContext);
    const { user } = useContext(AuthContext);

    const [lead, setLead] = useState();
    const [leadId, setLeadId] = useState();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(true);
    const [maxNumber, setMaxNumber] = useState(250);
    const [unsubscribe, setUnsubscribe] = useState();

    // Load user Follow ups
    useEffect(() => {
        let unsubscribe = () => {};
        if (!followUps) {
            console.log("loadFollowUps");
            unsubscribe = loadFollowUps(user, setFollowUps);
            addUnsubscribeStore(unsubscribe);
        }
    }, []);

    useEffect(() => {
        if (!appointments) {
            console.log("loadAppointments");
            const unsubscribe = loadAppointments(user, setAppointments);
            addUnsubscribeStore(unsubscribe);
        }
    }, []);

    useEffect(() => {
        if (!leadId) return;
        unsubscribe?.();
        const unsubscribeLocal = loadLead(leadId, setLead);
        setUnsubscribe(() => () => unsubscribeLocal());

        return () => {
            unsubscribeLocal?.();
        };
    }, [leadId]);

    const events = useMemo(() => {
        let events = [];
        if (followUps) {
            followUps.forEach((followUp) => {
                events.push({
                    title: `${followUp.clientName}`,
                    start: new Date(convertDate(followUp.date)),
                    id: followUp.id,
                    leadId: followUp.leadId,
                    type: "followUp",
                    backgroundColor: blue[500]
                });
            });
        }

        if (appointments) {
            appointments.forEach((appointment) => {
                events.push({
                    title: `${appointment.clientName}`,
                    start: new Date(convertDate(appointment.date)),
                    id: appointment.id,
                    leadId: appointment.leadId,
                    type: "appointments",
                    backgroundColor: yellow[800]
                });
            });
        }

        return events;
    }, [followUps, appointments]);

    const handleDateClick = (e) => {
        const { id } = e.event;
        const event = events.find((ev) => ev.id === id);
        setLeadId((prevState) => {
            if (prevState === event.leadId) return event.leadId;
            triggerSlide();
            return event.leadId;
        });
    };

    const triggerSlide = () => {
        setOpen((state) => !state);
        setTimeout(() => {
            setOpen((state) => !state);
        }, 100);
    };

    return (
        <Container maxWidth="md" disableGutters>
            <FullCalendar
                height={"65vh"}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                    left: "prev,today,next",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay"
                }}
                titleFormat={{ year: "numeric", month: "short" }}
                weekends={true}
                firstDay={1}
                events={events || []}
                eventContent={renderEventContent}
                eventClick={handleDateClick}
                buttonText={{
                    prev: "<",
                    today: "Today",
                    next: ">"
                }}
            />

            <LeadsFlexBox
                leads={lead ? [lead] : null}
                title={"Lead"}
                maxNumber={maxNumber}
                setMaxNumber={setMaxNumber}
                setLoading={setLoading}
                loading={loading}
                leadsMode={"ASSIGNED_LEADS"}
                triggerSlide={triggerSlide}
                displayHeader={false}
                enableSelect={false}
            />
        </Container>
    );
}

// a custom render function
function renderEventContent(eventInfo) {
    const { backgroundColor } = eventInfo.event;

    return (
        <GridContainer>
            <GridFlexBox fs bgcolor={backgroundColor}>
                <Name fs10 nowrap color="white">
                    {eventInfo.event.title}
                </Name>
            </GridFlexBox>
        </GridContainer>
    );
}
