import { collection, getDocs, query, where,onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";

export const getAgentListings =  (userId, setListing) => {
    try {
        // const db = getFirestore(app);
        const listingsCollection = collection(db, "listings");
        const q = query(listingsCollection, where("userId", "==", userId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const listing = mapSnapshot(querySnapshot);
            setListing(listing);
        });

        return unsubscribe;
    } catch (error) {
        console.error("Error retrieving data from Firestore:", error);
        return [];
    }
};
