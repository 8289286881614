import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import { Icon, IconButton, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ConfirmationContext } from "../context-utils/ConfirmationContext";
import { AuthContext } from "../context/AuthContext";
import { StoreContext } from "../context/StoreContext";
import addFolder from "../functions/addFolder";
import addLeadsIntoFolder from "../functions/addLeadsIntoFolder";
import deleteFolder from "../functions/deleteFolder";
import loadFoldersRT from "../functions/loadFolders";
import { ButtonC, GridContainer, GridDivider, GridFlexBox, Name, Title } from "../themes/themes";

export default function ManageFolders({ select, selectData, handleClose }) {
    const [folderName, setFolderName] = useState("");
    const [edit, setEdit] = useState(false);

    const { folders, setFolders, setUnsubscribeStore } = useContext(StoreContext);
    const { user } = useContext(AuthContext);
    const { openConfirmation } = useContext(ConfirmationContext);

    useEffect(() => {
        let unsubscribe = () => {};
        if (!folders) {
            unsubscribe = loadFoldersRT(user, setFolders);
            setFolders(folders);
        }

        setUnsubscribeStore((array) => {
            if (array) {
                let functions = [...array];
                functions.push(unsubscribe);
                return functions;
            } else {
                return [unsubscribe];
            }
        });
    }, []);

    const handleClickCreate = async () => {
        await addFolder(folderName, user);
        setFolderName("");
    };

    const handleAddIntoFolder = async (folder) => {
        await addLeadsIntoFolder(folder, user, selectData);
        handleClose();
    };

    const handleDelete = async (folder) => {
        const response = await openConfirmation("Delete folder?", "Press OK to confirm delete");
        if (response) {
            await deleteFolder(folder, user);
        }
    };

    return (
        <GridContainer>
            <GridFlexBox xs={10}>
                <Title>Folders Manager</Title>
            </GridFlexBox>
            <GridFlexBox xs={2}>
                <IconButton onClick={() => setEdit((edit) => !edit)}>{edit ? <CancelIcon /> : <EditIcon />}</IconButton>
            </GridFlexBox>
            <GridDivider />
            <GridFlexBox>
                <Name>{select.length} number of leads selected</Name>
            </GridFlexBox>
            <GridDivider />

            {folders?.map((folder) => (
                <GridFlexBox key={folder.id} fs ml1>
                    <ButtonC small color="secondary" onClick={() => handleAddIntoFolder(folder)}>
                        {folder.folderName}
                    </ButtonC>
                    {edit && (
                        <IconButton onClick={() => handleDelete(folder)}>
                            <Icon>clear</Icon>
                        </IconButton>
                    )}
                </GridFlexBox>
            ))}

            <GridFlexBox w="60%" m1>
                <TextField
                    value={folderName}
                    size="small"
                    fullWidth
                    label="New folder name"
                    required={false}
                    onChange={(e) => setFolderName(e.target.value)}
                />
            </GridFlexBox>
            <GridFlexBox w="40%" m1>
                <ButtonC small onClick={handleClickCreate}>
                    Create Folder
                </ButtonC>
            </GridFlexBox>
        </GridContainer>
    );
}
