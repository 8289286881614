import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export async function savePage(page, user) {
    try {
        const collectionRef = collection(db, "pages");
        const response = await addDoc(collectionRef, page);

        const userPageRef = doc(db, "users", user.id, "pages", response.id);
        await setDoc(userPageRef, { id: response.id, name: page.name }, { merge: true });

        console.log("Added new page to Firestore: " + page.name);

        return response;
    } catch (error) {
        console.log(error);
    }
}
