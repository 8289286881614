import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function updateName(name, lead) {
    try {
        const docRef = doc(db, "leads", lead.id);
        let newLead = { ...lead };
        let array = [...lead.field_data];
        lead.field_data.every((doc, i) => {
            if (doc.name.includes("name") || doc.name.includes("全名")) {
                array[i]["values"] = [name];
                return false;
            }
            return true;
        });
        newLead.field_data = array;
        newLead.name = name;

        await setDoc(docRef, newLead, { merge: true });
        console.log(lead.id, " updated");
        return newLead;
    } catch (error) {
        console.log(error);
    }
}
