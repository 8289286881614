import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where,
    writeBatch
} from "firebase/firestore";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ImageComponent from "../../components/Image/ImageComponent";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import useNotification from "../../context-utils/NotificationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";
import { GridContainer, GridDivider, GridFlexBox, Loading, Name, Title, addAdornment } from "../../themes/themes";
import UploadFilesButton from "./../../components/UploadFile/UploadFilesButton";

export default function FollowUpDesign({ flow }) {
    console.log("flow:", flow);
    const notify = useNotification();
    const [blockName, setBlockName] = React.useState("");
    const [blocks, setBlocks] = React.useState([]);
    const [selectedBlock, setSelectedBlock] = useState();
    const [scheduleWMessages, setScheduleWMessages] = useState();
    const [errorWMessages, setErrorWMessages] = useState();
    const [checked, setChecked] = useState([]);
    const [checkedError, setCheckedError] = useState([]);
    const [loadingSchedule, setLoadingSchedule] = useState(false);
    const [loadingError, setLoadingError] = useState(false);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (!flow.id || !user.id) return;
        const collectionRef = collection(db, "users", user.id, "whatsappFlows", flow.id, "blocks");
        const q = query(collectionRef, orderBy("orderId", "asc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const data = mapSnapshot(snapshot);
            setBlocks(data);
        });
        return unsubscribe;
    }, [user, flow]);

    //This one is to fetch the scheduled followup message
    useEffect(() => {
        if (!selectedBlock) return;
        const selectedBlockID = selectedBlock.id;
        const scheduleTaskRef = collection(db, "scheduledTasks");
        const q1 = query(
            scheduleTaskRef,
            where("blockId", "==", selectedBlockID),
            where("status", "==", "scheduled"),
            orderBy("scheduledTime", "desc")
        );
        console.log("Fetching scheduled follow up messages");

        setLoadingSchedule(true);
        const unsubScheduleList = onSnapshot(q1, (snapshot) => {
            let scheduleList = mapSnapshot(snapshot);
            scheduleList = scheduleList.map((obj) => {
                return {
                    ...obj,
                    scheduledTime: moment(new Date(obj.scheduledTime.seconds * 1000)).format("YYYY-MM-DD HH:mm:ss")
                };
            });
            console.log("scheduleList: ", scheduleList);

            setScheduleWMessages(scheduleList);
            setLoadingSchedule(false);
        });

        return unsubScheduleList;
    }, [selectedBlock]);

    //This one is to fetch the error followup message
    useEffect(() => {
        if (!selectedBlock) return;
        const selectedBlockID = selectedBlock.id;
        const scheduleTaskRef = collection(db, "scheduledTasks");
        const q2 = query(
            scheduleTaskRef,
            where("blockId", "==", selectedBlockID),
            where("status", "==", "error"),
            orderBy("scheduledTime", "desc")
        );
        console.log("Fetching error follow up messages");

        setLoadingError(true);
        const unsubErrorList = onSnapshot(q2, (snapshot) => {
            let errorList = mapSnapshot(snapshot);
            console.log("errorList: ", errorList);

            setErrorWMessages(errorList);
            setLoadingError(false);
        });

        return unsubErrorList;
    }, [selectedBlock]);

    //This will update the legacy data where the blocks doesnt have a field named count
    useEffect(() => {
        if (!selectedBlock || !scheduleWMessages || !errorWMessages) return;
        const updateBlockCount = async (ref, sum) => {
            try {
                await updateDoc(ref, {
                    count: sum
                });
                console.log("Successfully updated the count to ref: ", ref);
            } catch (err) {
                console.log(err.message);
            }
        };

        if (!(selectedBlock.count >= 0)) {
            console.log("Legacy data found, block: ", selectedBlock);

            //Get the sum of scheduleMessages and errorMessages
            const sum = scheduleWMessages.length + errorWMessages.length;
            console.log(`Sum of ${scheduleWMessages.length} + ${errorWMessages.length} = ${sum}`);

            const blocksRef = doc(db, selectedBlock.path);
            updateBlockCount(blocksRef, sum);
        }
    }, [selectedBlock, scheduleWMessages, errorWMessages]);

    const handleAddName = async (event) => {
        //modified by wayne 26-12-23 : add in control to cater if user key in empty or nothing and press add button, system will prompt to input block name.
        if (blockName && blockName.trim()) {
            const collectionRef = collection(db, "users", user.id, "whatsappFlows", flow.id, "blocks");
            await addDoc(collectionRef, {
                name: blockName,
                date: new Date(),
                createdBy: user.id,
                orderId: blocks.length,
                status: "active",
                flowId: flow.id,
                count: 0
            });
            setBlockName("");
        } else {
            notify("Please key in block name.", "error");
        }
    };

    const handleClickLoad = (block) => {
        console.log("handleClickLoad block: ", block);
        setSelectedBlock(block);
    };

    const handleScheduleDelete = async () => {
        setLoadingSchedule(true);

        // Create an array of promises to delete each checked item
        const deletePromises = checked.map(async (item) => {
            console.log(`Deleting item with ID: ${item.id}`);
            try {
                await deleteDoc(doc(db, "scheduledTasks", item.id));
                console.log(`Successfully deleted item with ID: ${item.id}`);
                return item.id; // Return the id of the successfully deleted item
            } catch (err) {
                console.log(`Error deleting item with ID: ${item.id}, error message: `, err.message);
                return null; // Return null if the item couldn't be deleted
            }
        });

        // Wait for all promises to complete
        const deletedIds = await Promise.all(deletePromises);

        console.log("Deleted items' id: ", deletedIds);

        setLoadingSchedule(false);

        // Clear the checked array
        setChecked([]);
    };

 const handleResend = async () => {
        setLoadingError(true);

        //Create an array of promises to resend each checked item
        const resendPromises = checkedError.map(async (item) => {
            console.log(`Resending item with ID: ${item.id}`);
            try {
                const currentTime = moment();
                //So by default all resend message will be resend according to time set by block
                const time = +selectedBlock.time || 0;
                const timeUnit = selectedBlock.timeUnit || "minutes";
                const futureTime = moment(currentTime).add(time, timeUnit);

                await updateDoc(doc(db, "scheduledTasks", item.id), {
                    status: "scheduled",
                    scheduledTime: new Date(futureTime)
                });
                console.log(`Successfully resend item with ID: ${item.id}`);
                return item.id; //Return the id of the successfully resend item
            } catch (err) {
                console.log(`Error resending item with ID: ${item.id}, error message: `, err.message);
                return null; //Return null if the item couldn't be resend
            }
        });

        // Wait for all promises to complete
        const resendIds = await Promise.all(resendPromises);

        console.log("Resend items' id: ", resendIds);

        setLoadingError(false);

        // Clear the checked array
        setCheckedError([]);
    };

    const handleErrorDelete = async () => {
        setLoadingError(true);

        // Create an array of promises to delete each checked item
        const deletePromises = checkedError.map(async (item) => {
            console.log(`Deleting error item with ID: ${item.id}`);
            try {
                await deleteDoc(doc(db, "scheduledTasks", item.id));
                console.log(`Successfully deleted error item with ID: ${item.id}`);
                return item.id; // Return the id of the successfully deleted item
            } catch (err) {
                console.log(`Error deleting error item with ID: ${item.id}, error message: `, err.message);
                return null; // Return null if the item couldn't be deleted
            }
        });

        // Wait for all promises to complete
        const deletedIds = await Promise.all(deletePromises);

        console.log("Deleted error items' id: ", deletedIds);

        setLoadingError(false);

        // Clear the checked array
        setCheckedError([]);
    };

    return (
        <Container maxWidth="none">
            <GridContainer>
                <GridFlexBox>
                    <Title>Follow up Design</Title>
                </GridFlexBox>
                <GridDivider />
            </GridContainer>
            <Grid container display="flex" spacing={1}>
                <Grid item xs={12} md={4}>
                    <Grid container display="flex" spacing={1}>
                        <Grid item display="flex" xs={12} flexDirection="column">
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Grid container display="flex" spacing={1}>
                                    <Grid item display="flex" xs={12}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label="Name"
                                            autoComplete="off"
                                            InputProps={addAdornment(handleAddName)}
                                            value={blockName}
                                            onChange={(e) => setBlockName(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        {blocks.map(
                            (block, i) =>
                                block.orderId !== 999 && (
                                    <React.Fragment key={block.id}>
                                        <BlockComponent
                                            block={block}
                                            flow={flow}
                                            blocks={blocks}
                                            i={i}
                                            handleClickLoad={handleClickLoad}
                                        />
                                    </React.Fragment>
                                )
                        )}
                    </Grid>
                </Grid>

                {/* Panel that shows all schedule tasks corresponding to a follow up block */}
                {/* This shows the follow up status scheduled */}
                <Grid item xs={12} md={4}>
                    {scheduleWMessages && (
                        <ScheduleList
                            title="Schedule List"
                            messages={scheduleWMessages}
                            setMessage={setErrorWMessages}
                            loading={loadingSchedule}
                            setLoading={setLoadingSchedule}
                            checked={checked}
                            setChecked={setChecked}
                            buttonFunc={handleScheduleDelete}
                            buttonStyle={{
                                text: "Delete",
                                icon: <DeleteIcon />,
                                color: "error"
                            }}
                            selectedBlock={selectedBlock}
                        />
                    )}
                </Grid>

                {/* This shows the follow up status error */}
                <Grid item xs={12} md={4}>
                    {errorWMessages && (
                        <ScheduleList
                            title="Error List"
                            messages={errorWMessages}
                            setMessage={setScheduleWMessages}
                            loading={loadingError}
                            setLoading={setLoadingError}
                            checked={checkedError}
                            setChecked={setCheckedError}
                            buttonFunc={handleResend}
                            buttonStyle={{
                                text: "Resend",
                                icon: <WhatsAppIcon />,
                                color: "success"
                            }}
                            selectedBlock={selectedBlock}
                            secondaryButtonFunc={handleErrorDelete}
                            secondaryButtonStyle={{
                                text: "Delete",
                                icon: <DeleteIcon />,
                                color: "error"
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}

function BlockComponent({ block, blocks, flow, i, handleClickLoad }) {
    const [timeUnit, setTimeUnit] = React.useState("minutes");
    const [time, setTime] = React.useState(0);
    const { user } = useContext(AuthContext);
    const [message, setMessage] = React.useState("");
    const [count, setCount] = useState(null);
    const [open, setOpen] = useState(false);
    const [dialog, setDialog] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (!block) return;
        console.log("block1 : ", block.count);
        setMessage(block.message || "");
        setTime(block.time || 0);
        setTimeUnit(block.timeUnit || "minutes");
        setCount(block.count);
    }, [block]);

    const handleChangeTimeUnit = async (event) => {
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", block.id);
        await updateDoc(docRef, { timeUnit: event.target.value });
    };

    const handleClickStatus = () => {
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", block.id);
        const newStatus = block.status === "active" ? "inactive" : "active";
        updateDoc(docRef, { status: newStatus });
    };

    const confirmation = useConfirmation();

    const handleClickDelete = async () => {
        console.log("block: ", block);
        console.log("count:", count);
        console.log("parsed count:", parseInt(count));
        if (count === null || count === undefined) {
            setDialog({
                title: "Delete block",
                content: 'Please click on "LOAD SCHEDULE LIST" button first. Thank you.'
            });
            handleClickOpen(true);
            return;
        } else if (count > 0) {
            setDialog({
                title: "Delete block",
                content: "Please delete all scheduled and error messages for this block first. Thank you."
            });
            handleClickOpen(true);
            return;
        }
        const response = await confirmation("Delete block", "Confirm?");
        if (response) {
            const newBlocks = [...blocks];
            newBlocks.splice(i, 1);
            console.log(newBlocks);
            try {
                const batch = writeBatch(db);
                const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", block.id);
                batch.delete(docRef);
                newBlocks.forEach((block, index) => {
                    const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", block.id);
                    batch.update(docRef, { orderId: index });
                });
                batch.commit();
            } catch (err) {
                console.log(err);
            }
        }
    };

    const handleSaveMessage = async () => {
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", block.id);
        await updateDoc(docRef, { message });
        console.log("message saved");
    };

    const handleSaveTime = async () => {
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", block.id);
        await updateDoc(docRef, { time });
    };

    const handleFile = async (files) => {
        console.log(files);
        if (block.files) {
            files = [...block.files, ...files];
        }
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", block.id);
        await updateDoc(docRef, { files: files });
        console.log("file saved");
    };

    const handleDeleteImage = async (i) => {
        let files = [...block.files];
        files.splice(i, 1);
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", block.id);
        await updateDoc(docRef, { files: files });
    };

    const handleClickUp = async () => {
        const firstBlock = blocks.find((block) => block.orderId === i - 1);
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", firstBlock.id);
        await updateDoc(docRef, { orderId: i });

        const docRef2 = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", block.id);
        await updateDoc(docRef2, { orderId: i - 1 });
    };

    const handleClickDown = async () => {
        const firstBlock = blocks.find((block) => block.orderId === i + 1);
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", firstBlock.id);
        await updateDoc(docRef, { orderId: i });

        const docRef2 = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", block.id);
        await updateDoc(docRef2, { orderId: i + 1 });
    };

    return (
        <Grid item display="flex" xs={12} flexDirection="column">
            {dialog && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{dialog.content}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>OK</Button>
                    </DialogActions>
                </Dialog>
            )}
            <Paper sx={{ width: "100%", padding: "8px" }}>
                <Grid container display="flex" spacing={1}>
                    <Grid item xs={9}>
                        <Button size="small" variant="outlined" onClick={() => handleClickLoad(block)}>
                            Load schedule list
                        </Button>
                    </Grid>
                    <Grid item xs={3} display="flex" justifyContent="flex-end">
                        {count !== null && count !== undefined && (
                            <Chip color={count !== 0 ? "primary" : "success"} size="small" label={count} />
                        )}
                    </Grid>
                    <Grid item display={"flex"} xs={8}>
                        <Box
                            display="flex"
                            alignItems="center"
                            gap="8px"
                            onClick={() => {
                                console.log(block);
                            }}
                        >
                            <IconButton
                                size="small"
                                disabled={block.orderId === blocks.length - 1}
                                onClick={handleClickDown}
                            >
                                <ArrowDownwardIcon />
                            </IconButton>
                            <IconButton size="small" disabled={block.orderId === 0} onClick={handleClickUp}>
                                <ArrowUpwardIcon />
                            </IconButton>
                            <Name>{block && `Order: ${block.orderId}`}</Name>
                            <Name>{block && block.name}</Name>
                        </Box>
                    </Grid>
                    <Grid item display={"flex"} justifyContent={"flex-end"} xs={4}>
                        <IconButton size="small" onClick={handleClickStatus}>
                            <Name color={block.status === "active" ? green[500] : red[500]}>
                                {block.status || "inactive"}
                            </Name>
                        </IconButton>
                        <IconButton size="small" onClick={() => handleClickDelete()}>
                            <Icon>delete</Icon>
                        </IconButton>
                    </Grid>

                    <Grid item display="flex" xs={12}>
                        <TextField
                            size="small"
                            fullWidth
                            label="Message"
                            multiline
                            onBlur={handleSaveMessage}
                            rows={10}
                            value={message}
                            onChange={(e) => setMessage(e.currentTarget.value)}
                        />
                    </Grid>
                    <Grid item display="flex" xs={4}>
                        <Box display={"flex"} alignContent={"center"}>
                            <UploadFilesButton handleFile={handleFile} label="Add File" />
                        </Box>
                    </Grid>
                    <Grid item display="flex" xs={4}>
                        <TextField
                            size="small"
                            fullWidth
                            type="number"
                            autoComplete="off"
                            value={time}
                            onChange={(e) => setTime(e.currentTarget.value)}
                            onBlur={handleSaveTime}
                        />
                    </Grid>
                    <Grid item display="flex" xs={4}>
                        <Select value={timeUnit} onChange={handleChangeTimeUnit} size={"small"} fullWidth>
                            <MenuItem value={"minutes"}>Minutes</MenuItem>
                            <MenuItem value={"hours"}>Hours</MenuItem>
                            <MenuItem value={"days"}>Days</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item display="flex" xs={12}></Grid>
                    <Grid item display="flex" xs={12} flexDirection={"column"}>
                        {block.files?.map((file, i) => (
                            <GridContainer key={`${file.downloadURL}${i}`}>
                                <Grid item display="flex" xs={6}>
                                    <Box display="flex" gap="8px">
                                        <ImageComponent image={file} width={["100px", "150px"]} />
                                    </Box>
                                </Grid>
                                <Grid item display="flex" xs={5}>
                                    <Name>{file.name}</Name>
                                </Grid>

                                <Grid item display="flex" xs={1}>
                                    <IconButton onClick={() => handleDeleteImage(i)}>
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </Grid>
                                <GridDivider />
                            </GridContainer>
                        ))}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}

export const ScheduleList = ({
    title,
    messages,
    setMessage,
    loading,
    setLoading,
    checked,
    setChecked,
    buttonFunc = () => {},
    buttonStyle = null,
    selectedBlock,
    secondaryButtonFunc = () => {},
    secondaryButtonStyle = null
}) => {
    const [searchSchedule, setSearchSchedule] = useState("");

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleToggleAll = () => {
        if (checked.length === filteredMessages.length) {
            setChecked([]);
        } else {
            setChecked(filteredMessages);
        }
    };

    const filteredMessages = useMemo(() => {
        if (!messages) return [];
        return messages.filter((message) => {
            return message.params.to.includes(searchSchedule);
        });
    }, [messages, searchSchedule]);

    return (
        <Grid container display="flex" spacing={1}>
            <Grid item display="flex" xs={12} flexDirection="column">
                <Paper sx={{ height: "80vh", width: "100%", padding: "8px" }}>
                    <Grid container sx={{ height: "100%", overflow: "hidden" }}>
                        <Grid item xs={12} display="flex" justifyContent="center">
                            <Typography variant="h6">{`${title} for [${selectedBlock.name}]`}</Typography>
                        </Grid>
                        <GridDivider />
                        <Grid container display="flex" alignItems="flex-end">
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    label="Search"
                                    variant="standard"
                                    size="small"
                                    value={searchSchedule}
                                    onChange={(e) => setSearchSchedule(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                {buttonStyle && (
                                    <Button
                                        variant="contained"
                                        color={buttonStyle.color}
                                        size="small"
                                        startIcon={buttonStyle.icon}
                                        disableElevation
                                        onClick={buttonFunc}
                                    >
                                        {buttonStyle.text} ({checked.length})
                                    </Button>
                                )}
                            </Grid>
                            <Grid item>
                                {secondaryButtonStyle && (
                                    <Button
                                        variant="contained"
                                        color={secondaryButtonStyle.color}
                                        size="small"
                                        startIcon={secondaryButtonStyle.icon}
                                        disableElevation
                                        onClick={secondaryButtonFunc}
                                    >
                                        {secondaryButtonStyle.text} ({checked.length})
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                        <GridDivider />
                        <Loading loading={loading} />
                        <Grid item xs={12}>
                            <List
                                sx={{
                                    width: "100%",
                                    bgcolor: "background.paper",
                                    height: "50vh",
                                    overflowY: "auto",
                                    paddingTop: "0"
                                }}
                            >
                                <ListItem
                                    sx={{
                                        position: "sticky",
                                        top: 0,
                                        zIndex: 99,
                                        backgroundColor: "background.paper",
                                        boxShadow:
                                            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={
                                                checked.length === filteredMessages.length &&
                                                filteredMessages.length !== 0
                                            }
                                            indeterminate={
                                                checked.length > 0 && checked.length < filteredMessages.length
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            onClick={handleToggleAll}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Select All" />
                                </ListItem>
                                {filteredMessages &&
                                    filteredMessages.map((scheduleWMessage) => {
                                        const labelId = `checkbox-list-label-${scheduleWMessage.id}`;

                                        return (
                                            <ListItem key={scheduleWMessage.id} disablePadding>
                                                <ListItemButton
                                                    role={undefined}
                                                    onClick={handleToggle(scheduleWMessage)}
                                                    dense
                                                >
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={checked.indexOf(scheduleWMessage) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ "aria-labelledby": labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        id={labelId}
                                                        primary={scheduleWMessage.params.to}
                                                        secondary={
                                                            scheduleWMessage.status === "scheduled"
                                                                ? scheduleWMessage.scheduledTime
                                                                : `Error: ${scheduleWMessage.message}`
                                                        }
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};
