import axios from "axios";
import { addDoc, collection, doc, increment, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

async function sendFBMessage(currentChatRoom, messageText, page) {
    const recipientId = currentChatRoom.id.split("-")[1];
    const pageId = currentChatRoom.id.split("-")[0];
    const metadata = JSON.stringify({ fromDJC: true });

    // Replace new line characters in the messageText with escaped new lines
    const formattedMessageText = messageText.replace(/\n/g, "\\n");

    let data = JSON.stringify({
        recipient: {
            id: recipientId
        },
        message: {
            text: formattedMessageText,
            metadata
        }
    });

    let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://graph.facebook.com/v19.0/me/messages?access_token=${page.accessToken}`,
        data: data,
        headers: {
            "Content-Type": "application/json"
        }
    };

    try {
        const response = await axios.request(config);
        //
        const data = {
            date: new Date(),
            lastMessage: messageText,
            lastMessageType: "text",
            senderId: pageId,
            recipientId: recipientId,
            pageId: pageId,
            unread: increment(1),
            object: page.object || ""
        };

        const chatRoomRef = doc(db, "fbMessages", currentChatRoom.id);
        await setDoc(chatRoomRef, data, { merge: true });
        const message = {
            from: pageId,
            to: recipientId,
            body: messageText,
            date: new Date(),
            chatRoomId: currentChatRoom.id,
            senderName: currentChatRoom.name || "",
            mid: response?.data?.message_id || ""
        };
        const collectionRef = collection(db, "fbMessages", currentChatRoom.id, "messages");
        await addDoc(collectionRef, message);
        console.log("Message added to Firestore: ", message);
        return { status: true, message: "Message sent successfully" };
    } catch (error) {
        const errMessage = error.response.data.error.message;
        console.log("sendFBMessage error:", errMessage);
        return { status: false, message: errMessage };
    }
}

export default sendFBMessage;
